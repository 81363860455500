@use 'styles/abstracts' as *;

.small {
  $nav: &;
  position: relative;

  .container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: rem($content-width-max);
    position: relative;
    background-color: #caccce;


    .wrapper {
      @include grid;
      @include grid--component-container;

      margin-inline: rem(16);
      max-width: rem($content-width-navigation);
      padding: rem(16) 0;
      position: relative;
      width: 100%;

      @include breakpoint(xlarge) {
        // Override default grid--component-container setting
        margin-inline: rem(16);
        max-width: rem($content-width-navigation);
      }

      @include breakpoint(navigation) {
        margin-inline: rem(0);
      }

      .bar {
        @include grid-column(1, span 6);

        align-items: center;
        background: $white;
        border-radius: rem(200);
        box-shadow: 0 rem(12) rem(24) rgb(9 7 37 / 7%);
        display: flex;
        height: rem(70);
        justify-content: space-between;
        min-width: rem(260);
        padding: rem(16);
        position: relative;
        z-index: 20;

        @include breakpoint(medium) {
          @include grid-column(1, span 12);
        }

        button:first-child {
          cursor: pointer;
          height: rem(24);
          left: rem(32);
          position: absolute;
          transform: rotate(0deg);
          transition: transform 0.3s ease-in-out;
          width: rem(32);
          z-index: 20;

          &:hover span {
            background-color: $red;
          }

          span {
            display: block;
            position: absolute;
            height: rem(2);
            width: 100%;
            background-color: $black;
            border-radius: rem(9);
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
          }

          span:nth-child(1) {
            top: 0;
          }

          span:nth-child(2),
          span:nth-child(3) {
            top: rem(10);
          }

          span:nth-child(4) {
            top: rem(20);
          }
        }

        button.isActive {
          span:nth-child(1) {
            top: rem(18);
            width: 0%;
            left: 50%;
          }

          span:nth-child(2) {
            transform: rotate(45deg);
          }

          span:nth-child(3) {
            transform: rotate(-45deg);
          }

          span:nth-child(4) {
            top: rem(18);
            width: 0%;
            left: 50%;
          }
        }

        a {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 20;
        }

        button:last-child {
          height: rem(30);
          right: rem(32);
          position: absolute;
          width: rem(30);
          z-index: 20;

          svg {
            fill: none;
            margin-top: 0;
            max-width: rem(32);
            min-width: rem(32);

            path {
              fill: $black;
            }
          }

          &:hover {
            svg {
              fill: none;

              path {
                fill: $red;
              }
            }
          }
        }
      }

      .search-active {
        button:last-child:hover svg path {
          stroke: $red;
        }
      }
    }
  }

  .menu {
    -webkit-backdrop-filter: blur(rem(15));
    backdrop-filter: blur(rem(15));
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0;
    height: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: height 0.5s ease-in-out;
    width: 100vw;
    z-index: 15;
  }

  // Firefox specific workaround because backdrop-filter isn't supported 
  @supports (-moz-appearance:none) {
    .menu {
      background-color: rgba(235, 235, 235, 0.99);
    }
  }

  .menu.isActive {
    height: 100vh;
    overflow-y: visible;
  }

  .search {
    -webkit-backdrop-filter: blur(rem(15));
    backdrop-filter: blur(rem(15));
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0;
    height: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: height 0.5s ease-in-out;
    width: 100vw;
    z-index: 15;
  }

  // Firefox specific workaround because backdrop-filter isn't supported
  @supports (-moz-appearance:none) {
    .search {
      background-color: rgba(235, 235, 235, 0.99);
    }
  }

  .search.isActive {
    height: 100vh;
  }

  .inner {
    background: linear-gradient(180deg, rgba(235, 235, 235, 0.1) 0, rgba(235, 235, 235, 1) rem(400));
    display: flex;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    max-width: rem($content-width-max);
    min-height: 100vh;
    padding: rem(130) 0 0;
    position: absolute;
    width: 100vw;

    .content {
      @include grid;
      @include grid--component-container;

      margin-inline: 0;
      max-width: rem($content-width-navigation);
      padding: 0;
      position: relative;
      width: 100%;

      @include breakpoint(xlarge) {
        // Override default grid--component-container setting
        max-width: rem($content-width-navigation);
      }

      @include breakpoint(navigation) {
        margin-inline: rem(0);
      }

    }
  }
}

/* TODO: For this to work it needs to be pulled up to the highest level Component & Header element */
.sticky {
  position: sticky;
  top: 0;
  z-index: 4;
}