@use 'styles/abstracts' as *;

.primary {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: row;
  padding: rem(2) 0;

  .label {
    color: $black;
    border-bottom: 1px solid #e5e5e5;
    flex:1
  }
}


.secondary {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding: rem(2) 0;

  .label {
    color: $black;
    border-bottom: 1px solid #e5e5e5;
    flex:1
  }
}
