@use 'styles/abstracts' as *;

.utility {
  position: relative;
  width: 100%;

  ul.bar {
    align-content: flex-start;
    align-items: stretch;
    background-color: $midnight-blue;
    display: flex;
    flex-direction: column;
    gap: $element-spacing-md;
    justify-content: flex-end;
    left: 0;
    max-height: rem(36);
    min-height: rem(36);
    position: absolute;
    right: 0;
    z-index: 7;

    @include breakpoint(medium) {
      align-items: center;
      flex-direction: row;
    }

    .bar {
      &__button {
        padding: rem(7);

        svg {
          margin-left: 0;
          margin-top: 0;
          max-width: rem(24);
          min-width: rem(24);
          transform: scale(1.5);

          path {
            fill: $light-gray;
          }
        }
      }

      &__button:hover {
        span {
          color: $red;
        }

        svg {
          path {
            fill: $red;
          }
        }
      }
    }

    > li:last-child {
      margin-right: $element-spacing-md;

      @include breakpoint(xlarge) {
        margin-right: calc(((100vw - 1160px) / 2) - 14px);
      }
    }
  }

  .menu {
    background-color: $white;
    border-bottom: rem(0) solid $midnight-blue;
    left: 0;
    max-height: rem(0);
    overflow: hidden;
    position: absolute;
    right: 0;
    top: rem(36);
    transition: max-height 500ms ease-in-out, border-bottom 500ms linear;
    z-index: 5;

    &__inner {
      margin: 0;
      transition: opacity 750ms ease-in-out;
      opacity: 0;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      gap: rem(25);
      justify-content: center;
      margin: 0 auto;
      max-width: rem($content-width);
      min-height: rem(415);
      padding: $element-v-spacing-md $element-spacing-md;
    }
  }

  .open .menu {
    border-bottom: rem(6) solid $midnight-blue;
    max-height: rem(1000);
    transition: max-height 500ms ease-in-out, border-bottom 150ms linear;
  }

  .active .menu {
    z-index: 6;

    &__inner {
      transition: opacity 750ms ease-in-out;
      opacity: 1;
    }
  }

  .links {
    padding-left: $element-spacing-md;

    > li {
      display: inline-block;
      padding-right: $element-spacing-md;
    }

    > li:last-child {
      padding-right: 0;
    }
  }

  .secondaryLinks {
    display: flex;

    > li {
      display: inline-block;
      padding-right: $element-spacing-md;
    }

    > li:last-child {
      padding-right: 0;
    }
  }
}
