@use 'styles/abstracts' as *;

/* TODO: Add inactive states to buttons and links matching Figma */
.button {
  align-items: center;
  border-radius: rem(50);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  max-height: rem(50);
  padding: rem(15) rem(49) rem(13);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  svg {
    margin-top: rem(-3);
    max-width: rem(20);
    min-width: rem(20);
  }

  svg + span {
    margin-left: rem(8);
  }

  span + svg {
    margin-left: rem(8);
  }

  &:hover {
    cursor: pointer;
  }
}

.button.outline {
  &:link,
  &:visited {
    background: transparent;
    border: rem(2) solid $black;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    background: $black;
    border: rem(2) solid $black;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:active {
    background: $red;
    border: rem(2) solid $red;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:disabled {
    background: transparent;
    border: rem(2) solid $dark-gray;
    color: $dark-gray;

    svg {
      path {
        stroke: $dark-gray;
      }
      path[fill] {
        fill: $dark-gray;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
  }
}

.button.outlineDark {
  &:link,
  &:visited {
    background: transparent;
    border: rem(2) solid $white;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:hover {
    background: $light-gray;
    border: rem(2) solid $light-gray;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:active {
    background: $red;
    border: rem(2) solid $red;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:disabled {
    background: transparent;
    border: rem(2) solid $dark-gray;
    color: $dark-gray;

    svg {
      path {
        stroke: $dark-gray;
      }
      path[fill] {
        fill: $dark-gray;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
  }
}

.button.primary {
  &:link,
  &:visited {
    background: $black;
    border: rem(2) solid $black;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:hover {
    background: transparent;
    border: rem(2) solid $black;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:active {
    background: transparent;
    border: rem(2) solid $red;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:disabled {
    background: $medium-gray;
    border: rem(2) solid $medium-gray;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
  }
}

.button.primaryRed {
  &:link,
  &:visited {
    background: $red;
    border: rem(2) solid $red;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:hover {
    background: transparent;
    border: rem(2) solid $red;
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    background: transparent;
    border: rem(2) solid $red;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:disabled {
    background: $medium-gray;
    border: rem(2) solid $medium-gray;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
  }
}

.button.secondary {
  &:link,
  &:visited {
    background: $white;
    border: rem(2) solid $white;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    background: transparent;
    border: rem(2) solid $white;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:active {
    background: transparent;
    border: rem(2) solid $red;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:disabled {
    background: $light-gray;
    border: rem(2) solid $light-gray;
    color: $dark-gray;

    svg {
      path {
        stroke: $dark-gray;
      }
      path[fill] {
        fill: $dark-gray;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
  }
}

.button.utility {
  padding: rem(4) rem(20) rem(2);

  &:link,
  &:visited {
    background: $white;
    border: rem(1) solid $white;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    background: $white;
    border: rem(1) solid $white;
    color: $red;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:active {
    background: $white;
    border: rem(1) solid $white;
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }
}

.link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  text-transform: uppercase;

  svg {
    margin-top: rem(-3);
    max-width: rem(24);
    min-width: rem(24);
  }

  svg + span {
    margin-left: rem(8);
  }

  span + svg {
    margin-left: rem(8);
  }

  &:hover {
    cursor: pointer;
  }
}

.link.text {
  &:link,
  &:visited {
    color: $black;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $black;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:disabled {
    color: $dark-gray;

    svg {
      path {
        stroke: $dark-gray;
      }
      path[fill] {
        fill: $dark-gray;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
    border-radius: rem(2);
  }
}

.link.textDark {
  &:link,
  &:visited {
    color: $white;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $black;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:disabled {
    color: $dark-gray;

    svg {
      path {
        stroke: $dark-gray;
      }
      path[fill] {
        fill: $dark-gray;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
    border-radius: rem(2);
  }
}

.link.textError {
  text-transform: none;

  &:link,
  &:visited {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }
}

.link.textFooter {
  text-transform: none;

  &:link,
  &:visited {
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textFooterHeader {
  text-transform: none;

  &:link,
  &:visited {
    color: $white;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textFooterSmall {
  text-transform: none;

  &:link,
  &:visited {
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textMainNavFaqLink {
  text-transform: uppercase;

  &:link,
  &:visited {
    color: $black;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      fill: $red;
      stroke: $red;
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textMainNavLink {
  text-transform: uppercase;

  &:link,
  &:visited {
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textMainNavLinkMobile {
  text-transform: uppercase;

  &:link,
  &:visited {
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textMainNavLinkUtilityMobile {
  text-transform: uppercase;

  &:link,
  &:visited {
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textNavBack {
  text-transform: none;

  &:link,
  &:visited {
    color: $black;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textNavL1 {
  text-transform: none;

  &:link,
  &:visited {
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textNavL2 {
  text-transform: none;

  &:link,
  &:visited {
    color: $near-black;

    svg {
      path {
        stroke: $near-black;
      }
      path[fill] {
        fill: $near-black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textNavL3 {
  text-transform: none;

  &:link,
  &:visited {
    color: $near-black;

    svg {
      path {
        stroke: $near-black;
      }
      path[fill] {
        fill: $near-black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.textUtility {
  text-transform: uppercase;

  &:link,
  &:visited {
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }
      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }
      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.utilityLink {
  text-transform: none;

  svg + span {
    margin-left: 0;
  }

  &:link,
  &:visited {
    color: $white;

    svg {
      max-height: rem(20);
      margin: rem(1) 0 0;

      path {
        stroke: $white;
      }

      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }

      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {
    color: $medium-blue;

    svg {
      path {
        stroke: $medium-blue;
      }

      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.cardLink {
  text-transform: none;

  svg+span {
    margin-left: 0;
  }

  &:link,
  &:visited {
    color: $midnight-blue;
    svg {
      max-height: rem(24);

      path {
        stroke: $red;
      }

      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }

      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {

    svg {
      path {
        stroke: $red;
      }

      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }
}

.link.subNavigation {
  text-transform: uppercase;

  svg+span {
    margin-left: 0;
  }

  &:link,
  &:visited {
    color: $black;
    svg {
      max-height: rem(24);

      path {
        stroke: $black;
      }

      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $red;

    svg {
      path {
        stroke: $red;
      }

      path[fill] {
        fill: $red;
        stroke: none;
      }
    }
  }

  &:active {

    svg {
      path {
        stroke: $medium-blue;
      }

      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.link.subNavigationSelected {
  text-transform: uppercase;

  svg+span {
    margin-left: 0;
  }

  &:link,
  &:visited {
    color: $black;
    svg {
      max-height: rem(24);

      path {
        stroke: $black;
      }

      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:hover {
    color: $black;

    svg {
      path {
        stroke: $black;
      }

      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:active {

    svg {
      path {
        stroke: $medium-blue;
      }

      path[fill] {
        fill: $medium-blue;
        stroke: none;
      }
    }
  }
}

.inline {
  display: inline-flex;
}

.fullWidth {
  display: flex;
}

.wrapper {
  cursor: pointer;
  display: block;
  text-decoration: none;
}
