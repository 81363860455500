/*
  Breakpoints - simple mixin for including breakpoints
  usage: @include breakpoint(large) { color: red; }
*/
$breakpoints: (
  tiny: 320px,
  small: 500px,
  medium: 768px,
  large: 992px,
  navigationSwitch: 1160px,
  xlarge: 1212px, // 1160 + 26*2 padding
  navigationLogo: 1299px,
  navigation: 1500px,
  xxlarge: 1700px
);

$breakpoints-map: (
  tiny:  ( min-width:  map-get($breakpoints, 'tiny') ),
  small:  ( min-width:  map-get($breakpoints, 'small') ),
  medium: ( min-width:  map-get($breakpoints, 'medium') ),
  large:  ( min-width: map-get($breakpoints, 'large') ),
  xlarge:  ( min-width: map-get($breakpoints, 'xlarge') ),
  xxlarge:  ( min-width: map-get($breakpoints, 'xxlarge') ),
  small-max:  ( max-width: map-get($breakpoints, 'medium') - 1px ),
  medium-max:  ( max-width: map-get($breakpoints, 'large') - 1px ),
  large-max:  ( max-width: map-get($breakpoints, 'xlarge') - 1px ),
  navigation-logo:  ( min-width: map-get($breakpoints, 'navigationLogo') ),
  navigation-switch:  ( min-width: map-get($breakpoints, 'navigationSwitch') ),
  navigation:  ( min-width: map-get($breakpoints, 'navigation') )
) !default;

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints-map
@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints-map, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints-map, $breakpoint))} {
      @content;
    }
  }
  
  // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
        + 'Available breakpoints are: #{map-keys($breakpoints-map)}.';
  }
}
