.hidden {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

/* Gooey Loader Codepen Animation: https://codepen.io/Izumenko/pen/MpWyXK */
@keyframes dot-3-move {
	20% {transform: scale(1)}
	45% {transform: translateY(-18px) scale(.45)}
	60% {transform: translateY(-90px) scale(.45)}
	80% {transform: translateY(-90px) scale(.45)}
	100% {transform: translateY(0px) scale(1)}
}

@keyframes dot-2-move {
	20% {transform: scale(1)}
	45% {transform: translate(-16px, 12px) scale(.45)}
	60% {transform: translate(-80px, 60px) scale(.45)}
	80% {transform: translate(-80px, 60px) scale(.45)}
	100% {transform: translateY(0px) scale(1)}
}

@keyframes dot-1-move {
	20% {transform: scale(1)}
	45% {transform: translate(16px, 12px) scale(.45)}
	60% {transform: translate(80px, 60px) scale(.45)}
	80% {transform: translate(80px, 60px) scale(.45)}
	100% {transform: translateY(0px) scale(1)}
}

@keyframes rotate-move {
	55% {transform: translate(-50%, -50%) rotate(0deg)}
	80% {transform: translate(-50%, -50%) rotate(360deg)}
	100% {transform: translate(-50%, -50%) rotate(360deg)}
}

@keyframes index {
	0%, 100% {z-index: 3}
	33.3% {z-index: 2}
	66.6% {z-index: 1}
}

.inline {
	background: rgba(#fff, .75);
	height: 100%;
  position: relative;
	width: 100%;
}

.fullscreen {
	background: rgba(#fff, .75);
	bottom: 0;
	height: 100%;
	left: 0;
	margin: 0 auto;
	padding: 25% 0 0;
  position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 20;
}
		
.container {
  animation: rotate-move 2s ease-in-out infinite;
  filter: url('#svg-filter');
  height: 200px;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 200px;
}

.dot {
  background-color: #000;
  border-radius: 50%;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
}

.three {
  @extend .dot;
  animation: dot-3-move 2s ease infinite, index 6s ease infinite;
  background-color: #eb002a;
}

.two {
  @extend .dot;
  animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
  background-color: #eb002a;
}

.one {
  @extend .dot;
  animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
  background-color: #eb002a;
}
