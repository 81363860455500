@use 'styles/abstracts' as *;

/*
*  Font families
*/
.fonts-loaded {
  .font {
    &--gotham {
      &-book {
        font-family: 'HCo Gotham SSm', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-weight: 350;
      }

      &-medium {
        font-family: 'HCo Gotham SSm', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-weight: 500;
      }

      &-bold {
        font-family: 'HCo Gotham SSm', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-weight: 700;
      }
    }

    &--reckless-neue {
      font-family: 'RecklessNeue', Helvetica, Arial, 'Lucida Grande', sans-serif;
      font-weight: 400;

      &-italic {
        font-family: 'RecklessNeue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-weight: 400;
        font-style: italic;
      }
    }
  }

  /*
  *  Override for One Trust modal styling
  */
  #onetrust-consent-sdk {
    font-family: 'HCo Gotham SSm', Helvetica, Arial, 'Lucida Grande', sans-serif;
  }
}

/*
*  Text sizes
*/
.headline-text-size {
  &--xx-large {
    font-size: rem(44);
    line-height: rem(48);
    letter-spacing: -0.03em;

    @include breakpoint(medium) {
      font-size: rem(68);
      line-height: rem(74);
      letter-spacing: -0.03em;
    }
  }

  &--x-large {
    font-size: rem(36);
    line-height: rem(40);
    letter-spacing: -0.03em;

    @include breakpoint(medium) {
      font-size: rem(48);
      line-height: rem(56);
      letter-spacing: -0.03em;
    }
  }

  &--large {
    font-size: rem(28);
    line-height: rem(32);
    letter-spacing: -0.03em;

    @include breakpoint(medium) {
      font-size: rem(36);
      line-height: rem(40);
      letter-spacing: -0.03em;
    }
  }

  &--medium {
    font-size: rem(24);
    line-height: rem(32);
    letter-spacing: -0.03em;
  }

  &--small {
    font-size: rem(20);
    line-height: rem(24);
    letter-spacing: -0.03em;
  }

  &--x-small {
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: -0.03em;
  }
}

.text-size {
  &--card-title {
    font-size: rem(20);
    line-height: rem(24);
    letter-spacing: -0.03em;

    @include breakpoint(medium) {
      font-size: rem(24);
      line-height: rem(32);
      letter-spacing: -0.03em;
    }
  }

  &--eyebrow {
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: -0.03em;

    @include breakpoint(medium) {
      font-size: rem(22);
      line-height: rem(26);
      letter-spacing: -0.03em;
    }
  }

  &--large {
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: -0.03em;

    @include breakpoint(medium) {
      font-size: rem(18);
      line-height: rem(24);
      letter-spacing: -0.03em;
    }
  }

  &--regular {
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: -0.03em;
  }

  &--small-alertBanner {
    font-size: rem(15);
    line-height: rem(23);
    letter-spacing: -0.03em;
  }

  &--small {
    font-size: rem(13);
    line-height: rem(22);
    letter-spacing: -0.03em;
  }

  &--x-small {
    font-size: rem(12);
    line-height: rem(20);
    letter-spacing: -0.03em;
  }

  &--cta {
    font-size: rem(14);
    line-height: rem(20);
    letter-spacing: 0.14em;
  }

  &--caps-accent {
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: 0.14em;
  }

  &--sub-navigation {
    font-size: rem(12);
    line-height: rem(24);
    letter-spacing: 0.14em;
  }

  &--navigation {
    font-size: rem(14);
    line-height: rem(24);
    letter-spacing: rem(1);
  }
}


/*
*  Text Colors
*/

/* Create 'text-color' rules based on the list of colors 
located in the 'colors' map, located in the 
'abstracts/colors' partial */
@include color-prop-rule('text-color-', 'color');

.text-color--inherit,
.text-color--none {
  color: inherit;
}