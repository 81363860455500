/* --- Primary --- */
$black: #000;
$red: #EB002A;
$white: #fff;

/* --- Secondary --- */
$dark-blue: #00416A;
$dark-yellow: #956E02;
$gold: #FFC72C;
$light-blue: #9BCBEB;
$light-teal: #6FD4DB;
$medium-blue: #41B6E6;
$midnight-blue: #022035;
$modified-light-blue: #3179A9;
$modified-medium-blue: #1989B8;
$teal: #007B8A;

/* --- Neutrals --- */
$dark-gray: #989A9B;
$light-gray: #EBEBEB;
$medium-gray: #CFD3D4;
$near-black: #2F3A41;
$page-base: #F6F6F9;

/* --- Miscellaneous --- */
$error-background: #FFDBE0;
$error-stroke: #EB002A;
$form-label: #666;
$form-stroke: #BABABA;
$success-background: #C0E7DD;
$success-stroke: #43D073;

/* --- Destionations --- */
$destinations-black: $black;
$destinations-dark-blue: #00416A;
$destinations-light-blue: $light-blue;
$destinations-light-gray: $light-gray;
$destinations-moss-green: #8DA346;
$destinations-red: $red;
$destinations-tan: #C9B863;
$destinations-white: $white;

/* --- Facilities --- */
$facilities-black: $black;
$facilities-dark-blue: $dark-blue;
$facilities-dark-gray: #54565A;
$facilities-green: #9BC455;
$facilities-gold: $gold;
$facilities-light-gray: $light-gray;
$facilities-medium-blue: #41B6E6;
$facilities-medium-gray: #98999B;
$facilities-red: $red;
$facilities-white: $white;

/* --- Refreshments --- */
$refreshments-aramark-light-gray: #DBD9D6;
$refreshments-black: $black;
$refreshments-coffee: #5B341B;
$refreshments-cream: #F1E2D2;
$refreshments-light-gray: $light-gray;
$refreshments-red: $red;
$refreshments-white: $white;

/* --- SE - Sports & Entertainment --- */
$se-pantone-135-c: #FEC558;
$se-pantone-1575-c: #FF7F30;
$se-pantone-297-c: #3A8DDE;
$se-pantone-432-c: #323E48;
$se-pantone-538-u: #CCD6E0;
$se-pantone-black-c: #222222;
$se-pantone-9225-c: #F4EFEA;

/* --- SN - Student Nutrition --- */
$sn-black: $black;
$sn-clear-skies: #84D1ED;
$sn-dark-gray: #54565A;
$sn-green-smoothie: #7AB44C;
$sn-light-gray: #EBEBEB;
$sn-mango: #E8A32B;
$sn-medium-gray: #98999B;
$sn-plum: #AB3F94;
$sn-red: $red;
$sn-watermelon: #E56E6A;
$sn-white: $white;
$sn-light-medium-gray: #98999B;
$sn-medium-dark-gray: #54565a;

$sn-plum-text: #9b3f84;
$sn-watermelon-text: #e46463;
$sn-mango-text: #ec9126;
$sn-green-smoothie-text: #74b143;
$sn-clear-skies-text: #47a9c3;

/* deselector */
$dimBlack:#333;


/* --- Color map to apply mixins --- */
$colors: (
	black: $black,
	dark-blue: $dark-blue,
	dark-gray: $dark-gray,
	dark-yellow: $dark-yellow,
	destinations-black: $destinations-black,
	destinations-dark-blue: $destinations-dark-blue,
	destinations-light-blue: $destinations-light-blue,
	destinations-light-gray: $destinations-light-gray,
	destinations-moss-green: $destinations-moss-green,
	destinations-red: $destinations-red,
	destinations-tan: $destinations-tan,
	destinations-white: $destinations-white,
	error-background: $error-background,
	error-stroke: $error-stroke,
	facilities-black: $facilities-black,
	facilities-dark-blue: $facilities-dark-blue,
	facilities-dark-gray: $facilities-dark-gray,
	facilities-green: $facilities-green,
	facilities-gold: $facilities-gold,
	facilities-light-gray: $facilities-light-gray,
	facilities-medium-blue: $facilities-medium-blue,
	facilities-medium-gray: $facilities-medium-gray,
	facilities-red: $facilities-red,
	facilities-white: $facilities-white,
	form-label: $form-label,
	form-stroke: $form-stroke,
	gold: $gold,
	light-blue: $light-blue,
	light-gray: $light-gray,
	light-teal: $light-teal,
	medium-blue: $medium-blue,
	medium-gray: $medium-gray,
	midnight-blue: $midnight-blue,
	modified-light-blue: $modified-light-blue,
	modified-medium-blue: $modified-medium-blue,
	near-black: $near-black,
	page-base: $page-base,
	red: $red,
	refreshments-aramark-light-gray: $refreshments-aramark-light-gray,
	refreshments-black: $refreshments-black,
	refreshments-coffee: $refreshments-coffee,
	refreshments-cream: $refreshments-cream,
	refreshments-light-gray: $refreshments-light-gray,
	refreshments-red: $refreshments-red,
	refreshments-white: $refreshments-white,
	se-pantone-135-c: $se-pantone-135-c,
	se-pantone-1575-c: $se-pantone-1575-c,
	se-pantone-297-c: $se-pantone-297-c,
	se-pantone-432-c: $se-pantone-432-c,
	se-pantone-538-u: $se-pantone-538-u,
	se-pantone-black-c: $se-pantone-black-c,
	se-pantone-9225-c: $se-pantone-9225-c,
	sn-black: $sn-black,
	sn-clear-skies: $sn-clear-skies,
	sn-dark-gray: $sn-dark-gray,
	sn-green-smoothie: $sn-green-smoothie,
	sn-light-gray: $sn-light-gray,
	sn-mango: $sn-mango,
	sn-medium-gray: $sn-medium-gray,
	sn-plum: $sn-plum,
	sn-red: $sn-red,
	sn-watermelon: $sn-watermelon,
	sn-white: $sn-white,
	sn-light-medium-gray:$sn-light-medium-gray,
	sn-medium-dark-gray:$sn-medium-dark-gray,

	sn-plum-text:$sn-plum-text,
	sn-watermelon-text:$sn-watermelon-text,
	sn-mango-text:$sn-mango-text,
	sn-green-smoothie-text:$sn-green-smoothie-text,
	sn-clear-skies-text:$sn-clear-skies-text,

	success-background: $success-background,
	success-stroke: $success-stroke,
	teal: $teal,
	white: $white,
	dimBlack:$dimBlack
);