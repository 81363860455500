/*
  Copyright (C) Hoefler & Co.
  This software is the property of Hoefler & Co. (H&Co).
  Your right to access and use this software is subject to the
  applicable License Agreement, or Terms of Service, that exists
  between you and H&Co. If no such agreement exists, you may not
  access or use this software for any purpose.
  This software may only be hosted at the locations specified in
  the applicable License Agreement or Terms of Service, and only
  for the purposes expressly set forth therein. You may not copy,
  modify, convert, create derivative works from or distribute this
  software in any way, or make it accessible to any third party,
  without first obtaining the written permission of H&Co.
  For more information, please visit us at http://typography.com.
*/

/*--- Gotham ---*/

// Gotham Book
@font-face {
  font-family: 'HCo Gotham SSm';
  src: url(/assets/fonts/gotham/GothamSSm-Book_Web.woff2) format('woff2'),
       url(/assets/fonts/gotham/GothamSSm-Book_Web.woff) format('woff');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

// Gotham Medium
@font-face {
  font-family: 'HCo Gotham SSm';
  src: url(/assets/fonts/gotham/GothamSSm-Medium_Web.woff2) format('woff2'),
       url(/assets/fonts/gotham/GothamSSm-Medium_Web.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Gotham Bold
@font-face {
  font-family: 'HCo Gotham SSm';
  src: url(/assets/fonts/gotham/GothamSSm-Bold_Web.woff2) format('woff2'),
       url(/assets/fonts/gotham/GothamSSm-Bold_Web.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/*--- Reckless Neue ---*/

// Reckless Neue Regular
@font-face {
  font-family: 'RecklessNeue';
  src: url(/assets/fonts/reckless-neue/RecklessNeue-Regular.woff2) format('woff2'),
       url(/assets/fonts/reckless-neue/RecklessNeue-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Reckless Neue Regular Italic
@font-face {
  font-family: 'RecklessNeue';
  src: url(/assets/fonts/reckless-neue/RecklessNeue-RegularItalic.woff2) format('woff2'),
       url(/assets/fonts/reckless-neue/RecklessNeue-RegularItalic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
