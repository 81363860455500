@use 'styles/abstracts' as *;

/* TODO: Add inactive states to buttons and links matching Figma */
.button {
  align-items: center;
  border-radius: rem(50);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  max-height: rem(50);
  min-height: rem(50);
  padding: rem(15) rem(49) rem(13);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  @include breakpoint(medium) {
    width: auto;
  }

  /* TODO: Update this when the Framework Icon Component is ready */
  svg {
    max-height: 1rem;
    min-width: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.button + .button {
  margin-top: rem(24);

  @include breakpoint(medium) {
    margin-left: rem(24);
    margin-top: 0;
  }
}

.primary {
  @extend .button;

  background: $black;
  border: rem(2) solid $black;
  color: $white;

  svg {
    path {
      stroke: $white;
    }
    path[fill] {
      fill: $white;
      stroke: none;
    }
  }

  &:hover {
    background: transparent;
    border: rem(2) solid $black;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:active {
    background: transparent;
    border: rem(2) solid $red;
    color: $black;

    svg {
      path {
        stroke: $black;
      }
      path[fill] {
        fill: $black;
        stroke: none;
      }
    }
  }

  &:disabled {
    background: $medium-gray;
    border: rem(2) solid $medium-gray;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
  }
}

.secondary {
  @extend .button;

  background: $white;
  border: rem(2) solid $black;
  color: $black;

  svg {
    path {
      stroke: $black;
    }
    path[fill] {
      fill: $black;
      stroke: none;
    }
  }

  &:hover {
    background: transparent;
    border: rem(2) solid $white;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
      path[fill] {
        fill: $white;
        stroke: none;
      }
    }
  }

  &:disabled {
    background: $light-gray;
    border: rem(2) solid $light-gray;
    color: $dark-gray;

    svg {
      path {
        stroke: $dark-gray;
      }
      path[fill] {
        fill: $dark-gray;
        stroke: none;
      }
    }
  }

  &:focus {
    outline: rem(2) solid $medium-blue;
    outline-offset: rem(3);
  }
}
