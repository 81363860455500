@use 'styles/abstracts' as *;

.card {
  $nuc: &;

  display: flex;
  flex: 0 0 rem(175);
  flex-direction: column;
  max-width: rem(200);
  min-width: rem(200);
  padding: $element-v-spacing-xl 0 $element-v-spacing-md;
  width: rem(200);

  a:hover,
  a:focus {
    img {
      transform: scale(1.25);
    }

    .icon-arrow-animated {
      @extend .common-icon-arrow-animated-active;
    }
  }

  .image {
    border-radius: rem(100);
    margin: 0 rem(45) $element-v-spacing-md;
    max-width: rem(120);
    min-height: rem(175);
    overflow: hidden;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0); // Fixes Safari issues with border-radius on hover

    img {
      max-width: rem(120);
      min-height: rem(175);
      transition: all .5s ease;
    }
  }

  .title {
    flex: 0 0 20%;
    margin-bottom: $element-v-spacing-md;
    padding: 0 rem(25);
    text-align: center;
  }

  .link {
    align-items: center;
    display: flex;
    flex: 0 0 10%;
    justify-content: center;
    margin-bottom: $element-v-spacing-sm;
    text-align: center;
    
    .icon-arrow-animated {
      @extend .common-icon-arrow-animated-base;
    
      > span {
        @extend .common-icon-arrow-animated-head;
      }
    }
  }
}
