@use '../colors' as *;

@mixin color-prop-rule($prefix, $prop, $pseudo:null) {
	@each $color-key, $color in $colors {
		@if($pseudo) {
			.#{$prefix}-#{'' + $color-key}:#{$pseudo} {
				#{$prop}: $color;
			}
		} @else {
			.#{$prefix}-#{'' + $color-key} {
				#{$prop}: $color;
			}
		}
	}
}
