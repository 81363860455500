@use 'styles/abstracts' as *;

.menu {
  $menu: &;

  @include grid-column(1, span 6);

  grid-row: 1;
  overflow: hidden;
  padding: rem(60) 0 rem(400);
  position: relative;
  width: 100vw;

  @include breakpoint(medium) {
    @include grid-column(1, span 12);
  }

  .menu-section-link {
    > a,
    > button {
      color: $black;
      justify-content: space-between;
      padding: rem(16) rem(32);
      text-transform: uppercase;
      width: 100%;

      span {
        white-space: break-spaces;
        text-align: left;
      }

      svg {
        min-height: rem(24);
        min-width: rem(24);

        path {
          stroke: $black;
        }
      }
    
      &:hover {
        color: $red;

        svg {
          path {
            stroke: $red;
          }
        }
      }

      &:active {
        color: $medium-blue;

        svg {
          path {
            stroke: $medium-blue;
          }
        }
      }
    }
    .highlight { 
      span {
        font-weight: 600;
        color: $black;
      }
     }
  }

  .menu-section-link-selected {
    > a,
    > button {
      color: $black;
      justify-content: space-between;
      padding: rem(16) rem(32);
      text-transform: uppercase;
      width: 100%;

      span {
        white-space: break-spaces;
        text-align: left;
        font-family: 'HCo Gotham SSm', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-weight: 700;
        color: $black;
      }

      svg {
        min-height: rem(24);
        min-width: rem(24);

        path {
          stroke: $black;
        }
      }

      &:hover {
        color: $red;

        svg {
          path {
            stroke: $red;
          }
        }
      }

      &:active {
        color: $medium-blue;

        svg {
          path {
            stroke: $medium-blue;
          }
        }
      }
    }
  }
}

.section {
  $sect: &;

  @include grid-column(1, span 6);

  grid-row: 1;
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: max-height 0s linear 0.5s;

  @include breakpoint(medium) {
    @include grid-column(1, span 12);
  }
  
  > button {
    color: $black;
    justify-content: flex-start;
    margin: 0 0 rem(10);
    padding: rem(16) 0 rem(16) rem(25);
    text-transform: uppercase;
    width: 100%;

    span {
      margin-left: 0;
    }

    svg {
      min-height: rem(24);
      min-width: rem(24);

      path {
        stroke: $red;
      }
    }

    &:hover {
      color: $red;

      svg {
        path {
          stroke: $red;
        }
      }
    }

    &:active {
      color: $medium-blue;

      svg {
        path {
          stroke: $medium-blue;
        }
      }
    }
  }

  .section-content {
    background: linear-gradient(180deg, rgba(152,154,155,0.25) 0, rgba(235,235,235,1) rem(100));
    padding-bottom: rem(400);

    .section-list {

      a {
        color: $near-black;
        justify-content: space-between;
        padding: rem(10) rem(32);

        &:hover {
          color: $red;

          svg {
            path {
              stroke: $red;
            }
          }
        }

        &:active {
          color: $medium-blue;

          svg {
            path {
              stroke: $medium-blue;
            }
          }
        }
      }

      .section-overview {
        align-items: center;
        background: $white;
        border-radius: 0 rem(50) rem(50) 0;
        box-shadow: 0 rem(12) rem(24) rgb(9 7 37 / 7%);
        display: inline-flex;
        justify-content: space-between;
        margin: rem(32) rem(32) rem(32) 0;

        a {
          align-items: center;
          color: $black;
          display: flex;
          padding: rem(12) rem(32) rem(12) rem(16);
          text-transform: uppercase;

          svg {
            min-height: rem(24);
            min-width: rem(24);

            path {
              stroke: $red;
            }
          }

          &:hover {
            color: $red;

            svg {
              path {
                stroke: $red;
              }
            }
          }

          &:active {
            color: $medium-blue;

            svg {
              path {
                stroke: $medium-blue;
              }
            }
          }

          div:first-child {
            margin-right: rem(6)
          }
        }

        div + div {
          margin-top: rem(1);
        }
      }

      .section-list-header {
        border-bottom: rem(1) solid $medium-gray;
        margin: 0 rem(32) rem(15);

        a {
          padding: rem(10) 0;
          text-transform: uppercase;
        }
       a.highlight {
        span {
          font-weight: 700;
          color: $black;
        }
       }
      }

      .section-list-link {
        overflow: visible;
        .section-list-submenu {
          padding-left: rem(16);
        }
        a.highlight {
          span {
            font-weight: 700;
            color: $black;
          }
         }
      }

      .section-list-link + .section-list-header {
        margin-top: rem(32);
      }

      .section-list-link-l4 {
        overflow: visible;
        padding-left: rem(16);
        a.highlight {
          span {
            font-weight: 700;
            color: $black;
          }
         }
      }

      .section-list-link-l5 {
        overflow: visible;
        padding-left: rem(32);
        a.highlight {
          span {
            font-weight: 700;
            color: $black;
          }
         }
      }

    }
  }
}

.section.isActive {
  max-height: rem(3000);
  min-height: calc(100vh - rem(130)); // height minus padding-top to avoid unnecesary scrolling
}

.menu-wrapper {
  bottom: 0;
  left: -100vw;
  position: absolute;
  right: 0;
  top: 0;
  transition: left 0.5s ease-in-out;
  width: 100vw;
}

.menu-wrapper.isActive {
  left: 0;
}

.sections-wrapper {
  bottom: 0;
  left: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  transition: left 0.5s ease-in-out;
  width: 100vw;
}

.sections-wrapper.isActive {
  left: 0;
}
