@use 'styles/abstracts' as *;

.navigation {
  $nav: &;

  // margin-bottom: rem(-102);

  @include breakpoint(navigation-switch) {
    margin-bottom: rem(-114);
  }
}