@use 'styles/abstracts' as *;

.primary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-top: rem(9);

  .dirty {
    border: 1px solid $black !important;
  }

  .wrapper {
    width: 100%;
    padding: rem(20);
    border-radius: rem(30);
    border: 1px solid $form-stroke;
  }

  .label {
    color: $dark-gray;
    position: absolute;
    top: rem(0);
    left: rem(25);
    border-radius: 40px;
    background-color: $white;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 18px;
      color: $black;
    }
  }

  .label-success {
    background-color: $success-background;

    span {
      color: $success-stroke;
    }
  }

  .label-error {
    span {
      color: $error-stroke;
    }
  }

  .error {
    padding: rem(18) rem(32) 0 rem(32);
  }

  .wrapper-success {
    border: 1px solid $success-stroke !important;
  }

  .wrapper-error {
    border: 1px solid $error-stroke !important;
  }

  .hideCheckBoxField {
    display: none;
  }

  .item {
    border-radius: rem(50);
    padding-left: rem(12);
    input[type=checkbox] {
      accent-color: $medium-gray;
    }
    &:hover {
      background-color: $page-base;
    }
  }
}
