@use 'styles/abstracts' as *;

/* Spacing Options
$component-spacing-zero | 0
$component-spacing-sm | 20px
$component-spacing-md | 40px
$component-spacing-lg | 60px
$component-spacing-xl | 80px
$component-spacing-xxl | 100px
$component-spacing-xxxl | 120px
*/

/* Component List
[data-component='Accordion']
[data-component='Asset5050']
[data-component='AssetImage']
[data-component='AssetVideo']
[data-component='Banner']
[data-component='BannerArticle']
[data-component='BannerFeature']
[data-component='BannerFullWidth']
[data-component='BannerWithCard']
[data-component='CardStack']
[data-component='Carousel']
[data-component='Columns']
[data-component='CallToAction']
[data-component='EmailCapture']
[data-component='Feed']
[data-component='Form']
[data-component='Footer']
[data-component='RichText']
[data-component='SocialSharing']
[data-component='Statement']
[data-component='StatementWithImage']
[data-component='SubNavigation']
*/

/* SET DEFAULT SPACING TO LARGE FOR ALL COMPONENTS ON DESKTOP & MOBILE */
[data-component] + [data-component='Accordion'],
[data-component] + [data-component='Asset5050'],
[data-component] + [data-component='AssetImage'],
[data-component] + [data-component='AssetVideo'],
[data-component] + [data-component='Banner'],
[data-component] + [data-component='BannerFeature'],
[data-component] + [data-component='BannerFullWidth'],
[data-component] + [data-component='BannerWithCard'],
[data-component] + [data-component='CardStack'],
[data-component] + [data-component='Carousel'],
[data-component] + [data-component='Columns'],
[data-component] + [data-component='CallToAction'],
[data-component] + [data-component='EmailCapture'],
[data-component] + [data-component='Feed'],
[data-component] + [data-component='Form'],
[data-component] + [data-component='Navigation'],
[data-component] + [data-component='RichText'],
[data-component] + [data-component='Search'],
[data-component] + [data-component='Statement'],
[data-component] + [data-component='StatementWithImage'],
[data-component] + [data-component='SubNavigation'] { margin-top: $component-spacing-md; }

/* COMPONENT SPECIFIC OVERRIDES */
/* ACCORDION */
[data-component='Accordion'] + [data-component='Asset5050']                   { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='AssetImage']                  { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='AssetVideo']                  { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='Banner']                      { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='CardStack']                   { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='Carousel']                    { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='Columns']                     { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='EmailCapture']                { margin-top: $component-spacing-zero; }
[data-component='Accordion'] + [data-component='Feed']                        { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='Form']                        { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='RichText']                    { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='Statement']                   { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='StatementWithImage']          { margin-top: $component-spacing-sm; }
[data-component='Accordion'] + [data-component='SubNavigation']               { margin-top: $component-spacing-sm; }
main [data-component='Accordion']:last-child                                  { margin-bottom: $component-spacing-sm; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='Accordion'] + [data-component='Asset5050']                 { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='AssetImage']                { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='AssetVideo']                { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='Banner']                    { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='CardStack']                 { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='Carousel']                  { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='Columns']                   { margin-top: $component-spacing-lg; }
  [data-component='Accordion'] + [data-component='EmailCapture']              { margin-top: $component-spacing-md; }
  [data-component='Accordion'] + [data-component='Feed']                      { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='Form']                      { margin-top: $component-spacing-xl; }
  [data-component='Accordion'] + [data-component='RichText']                  { margin-top: $component-spacing-lg; }
  [data-component='Accordion'] + [data-component='Statement']                 { margin-top: $component-spacing-md; }
  [data-component='Accordion'] + [data-component='SubNavigation']             { margin-top: $component-spacing-md; }
  [data-component='Accordion'] + [data-component='StatementWithImage']        { margin-top: $component-spacing-md; }
  main [data-component='Accordion']:last-child                                { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* ASSET 50/50 */
[data-component='Asset5050'] + [data-component='Accordion']                   { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='AssetImage']                  { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='AssetVideo']                  { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='Banner']                      { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='CardStack']                   { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='Carousel']                    { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='Columns']                     { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='EmailCapture']                { margin-top: $component-spacing-zero; }
[data-component='Asset5050'] + [data-component='Feed']                        { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='Form']                        { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='RichText']                    { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='Statement']                   { margin-top: $component-spacing-sm; }
[data-component='AssetImage'] + [data-component='StatementWithImage']         { margin-top: $component-spacing-sm; }
[data-component='Asset5050'] + [data-component='SubNavigation']               { margin-top: $component-spacing-sm; }
main [data-component='Asset5050']:last-child                                  { margin-bottom: $component-spacing-sm; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='Asset5050'] + [data-component='Accordion']                 { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='AssetImage']                { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='AssetVideo']                { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='Banner']                    { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='CardStack']                 { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='Carousel']                  { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='Columns']                   { margin-top: $component-spacing-lg; }
  [data-component='Asset5050'] + [data-component='EmailCapture']              { margin-top: $component-spacing-md; }
  [data-component='Asset5050'] + [data-component='Feed']                      { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='Form']                      { margin-top: $component-spacing-xl; }
  [data-component='Asset5050'] + [data-component='RichText']                  { margin-top: $component-spacing-lg; }
  [data-component='Asset5050'] + [data-component='Statement']                 { margin-top: $component-spacing-md; }
  [data-component='Asset5050'] + [data-component='SubNavigation']             { margin-top: $component-spacing-md; }
  [data-component='Asset5050'] + [data-component='StatementWithImage']        { margin-top: $component-spacing-md; }
  main [data-component='Asset5050']:last-child                                { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* ASSET IMAGE */
[data-component='AssetImage'] + [data-component='Accordion']                  { margin-top: $component-spacing-md; }
[data-component='AssetImage'] + [data-component='Asset5050']                  { margin-top: $component-spacing-md; }
[data-component='AssetImage'] + [data-component='AssetImage']                 { margin-top: $component-spacing-md; }
[data-component='AssetImage'] + [data-component='AssetVideo']                 { margin-top: $component-spacing-md; }
[data-component='AssetImage'] + [data-component='Banner']                     { margin-top: $component-spacing-md; }
[data-component='AssetImage'] + [data-component='CardStack']                  { margin-top: $component-spacing-xxl; }
[data-component='AssetImage'] + [data-component='Carousel']                   { margin-top: $component-spacing-xxl; }
[data-component='AssetImage'] + [data-component='Columns']                    { margin-top: $component-spacing-xxl; }
[data-component='AssetImage'] + [data-component='EmailCapture']               { margin-top: $component-spacing-md; }
[data-component='AssetImage'] + [data-component='Form']                       { margin-top: $component-spacing-xxl; }
[data-component='AssetImage'] + [data-component='RichText']                   { margin-top: $component-spacing-xxl; }
main [data-component='AssetImage']:last-child                                 { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='AssetImage'] + [data-component='Accordion']                { margin-top: $component-spacing-lg; }
  [data-component='AssetImage'] + [data-component='Asset5050']                { margin-top: $component-spacing-lg; }
  [data-component='AssetImage'] + [data-component='AssetImage']               { margin-top: $component-spacing-lg; }
  [data-component='AssetImage'] + [data-component='AssetVideo']               { margin-top: $component-spacing-lg; }
  [data-component='AssetImage'] + [data-component='Banner']                   { margin-top: $component-spacing-lg; }
  [data-component='AssetImage'] + [data-component='CardStack']                { margin-top: $component-spacing-xxl; }
  [data-component='AssetImage'] + [data-component='Carousel']                 { margin-top: $component-spacing-xxl; }
  [data-component='AssetImage'] + [data-component='Columns']                  { margin-top: $component-spacing-md; }
  [data-component='AssetImage'] + [data-component='RichText']                 { margin-top: $component-spacing-lg; }
  [data-component='AssetImage'] + [data-component='Statement']                { margin-top: $component-spacing-md; }
  [data-component='AssetImage'] + [data-component='StatementWithImage']       { margin-top: $component-spacing-md; }

  main [data-component='AssetImage']:last-child                               { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* ASSET VIDEO */
[data-component='AssetVideo'] + [data-component='Accordion']                  { margin-top: $component-spacing-md; }
[data-component='AssetVideo'] + [data-component='Asset5050']                  { margin-top: $component-spacing-md; }
[data-component='AssetVideo'] + [data-component='AssetImage']                 { margin-top: $component-spacing-md; }
[data-component='AssetVideo'] + [data-component='AssetVideo']                 { margin-top: $component-spacing-md; }
[data-component='AssetVideo'] + [data-component='Banner']                     { margin-top: $component-spacing-md; }
[data-component='AssetVideo'] + [data-component='CardStack']                  { margin-top: $component-spacing-xxl; }
[data-component='AssetVideo'] + [data-component='Carousel']                   { margin-top: $component-spacing-xxl; }
[data-component='AssetVideo'] + [data-component='Columns']                    { margin-top: $component-spacing-xxl; }
[data-component='AssetVideo'] + [data-component='EmailCapture']               { margin-top: $component-spacing-md; }
[data-component='AssetVideo'] + [data-component='Form']                       { margin-top: $component-spacing-xxl; }
[data-component='AssetVideo'] + [data-component='RichText']                   { margin-top: $component-spacing-xxl; }
main [data-component='AssetVideo']:last-child                                 { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='AssetVideo'] + [data-component='Accordion']                { margin-top: $component-spacing-lg; }
  [data-component='AssetVideo'] + [data-component='Asset5050']                { margin-top: $component-spacing-lg; }
  [data-component='AssetVideo'] + [data-component='AssetImage']               { margin-top: $component-spacing-lg; }
  [data-component='AssetVideo'] + [data-component='AssetVideo']               { margin-top: $component-spacing-lg; }
  [data-component='AssetVideo'] + [data-component='Banner']                   { margin-top: $component-spacing-lg; }
  [data-component='AssetVideo'] + [data-component='CardStack']                { margin-top: $component-spacing-xxl; }
  [data-component='AssetVideo'] + [data-component='Carousel']                 { margin-top: $component-spacing-xxl; }
  [data-component='AssetVideo'] + [data-component='Columns']                  { margin-top: $component-spacing-md; }
  [data-component='AssetVideo'] + [data-component='RichText']                 { margin-top: $component-spacing-lg; }
  [data-component='AssetVideo'] + [data-component='Statement']                { margin-top: $component-spacing-md; }
  [data-component='AssetVideo'] + [data-component='StatementWithImage']       { margin-top: $component-spacing-md; }

  main [data-component='AssetVideo']:last-child                               { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* BANNER - PAGE */
[data-component='Banner'] + [data-component='Accordion']                      { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='Asset5050']                      { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='AssetImage']                     { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='AssetVideo']                     { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='Banner']                         { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='CardStack']                      { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='Carousel']                       { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='Columns']                        { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='EmailCapture']                   { margin-top: $component-spacing-zero; }
[data-component='Banner'] + [data-component='Feed']                           { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='Form']                           { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='RichText']                       { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='Statement']                      { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='StatementWithImage']             { margin-top: $component-spacing-sm; }
[data-component='Banner'] + [data-component='SubNavigation']                  { margin-top: $component-spacing-sm; }
main [data-component='Banner']:last-child                                     { margin-bottom: $component-spacing-sm; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='Banner'] + [data-component='Accordion']                    { margin-top: $component-spacing-lg; }
  [data-component='Banner'] + [data-component='Asset5050']                    { margin-top: $component-spacing-lg; }
  [data-component='Banner'] + [data-component='AssetImage']                   { margin-top: $component-spacing-xxl; }
  [data-component='Banner'] + [data-component='AssetVideo']                   { margin-top: $component-spacing-xxl; }
  [data-component='Banner'] + [data-component='Banner']                       { margin-top: $component-spacing-lg; }
  [data-component='Banner'] + [data-component='CardStack']                    { margin-top: $component-spacing-xxl; }
  [data-component='Banner'] + [data-component='Carousel']                     { margin-top: $component-spacing-xxl; }
  [data-component='Banner'] + [data-component='Columns']                      { margin-top: $component-spacing-lg; }
  [data-component='Banner'] + [data-component='EmailCapture']                 { margin-top: $component-spacing-md; }
  [data-component='Banner'] + [data-component='Feed']                         { margin-top: $component-spacing-xl; }
  [data-component='Banner'] + [data-component='Form']                         { margin-top: $component-spacing-xxl; }
  [data-component='Banner'] + [data-component='RichText']                     { margin-top: $component-spacing-lg; }
  [data-component='Banner'] + [data-component='Statement']                    { margin-top: $component-spacing-md; }
  [data-component='Banner'] + [data-component='StatementWithImage']           { margin-top: $component-spacing-md; }
  [data-component='Banner'] + [data-component='SubNavigation']                { margin-top: $component-spacing-md; }
  main [data-component='Banner']:last-child                                   { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* Lower page position spacing */
main [data-component='Banner']:not(:nth-child(1)) > div > div:last-child {
  padding-top: rem(10);
}

/* BANNER - ARTICLE */
[data-component='BannerArticle'] + [data-component='Accordion']               { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='Asset5050']               { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='AssetImage']              { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='AssetVideo']              { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='Banner']                  { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='CardStack']               { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='Carousel']                { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='Columns']                 { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='EmailCapture']            { margin-top: $component-spacing-zero; }
[data-component='BannerArticle'] + [data-component='Feed']                    { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='Form']                    { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='RichText']                { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='Statement']               { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='StatementWithImage']      { margin-top: $component-spacing-sm; }
[data-component='BannerArticle'] + [data-component='SubNavigation']           { margin-top: $component-spacing-sm; }
main [data-component='BannerArticle']:last-child                              { margin-bottom: $component-spacing-sm; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='BannerArticle'] + [data-component='Accordion']             { margin-top: $component-spacing-lg; }
  [data-component='BannerArticle'] + [data-component='Asset5050']             { margin-top: $component-spacing-lg; }
  [data-component='BannerArticle'] + [data-component='AssetImage']            { margin-top: $component-spacing-xxl; }
  [data-component='BannerArticle'] + [data-component='AssetVideo']            { margin-top: $component-spacing-xxl; }
  [data-component='BannerArticle'] + [data-component='Banner']                { margin-top: $component-spacing-lg; }
  [data-component='BannerArticle'] + [data-component='CardStack']             { margin-top: $component-spacing-xxl; }
  [data-component='BannerArticle'] + [data-component='Carousel']              { margin-top: $component-spacing-xxl; }
  [data-component='BannerArticle'] + [data-component='Columns']               { margin-top: $component-spacing-lg; }
  [data-component='BannerArticle'] + [data-component='EmailCapture']          { margin-top: $component-spacing-md; }
  [data-component='BannerArticle'] + [data-component='Feed']                  { margin-top: $component-spacing-xl; }
  [data-component='BannerArticle'] + [data-component='Form']                  { margin-top: $component-spacing-xxl; }
  [data-component='BannerArticle'] + [data-component='RichText']              { margin-top: $component-spacing-lg; }
  [data-component='BannerArticle'] + [data-component='Statement']             { margin-top: $component-spacing-md; }
  [data-component='BannerArticle'] + [data-component='StatementWithImage']    { margin-top: $component-spacing-md; }
  [data-component='BannerArticle'] + [data-component='SubNavigation']         { margin-top: $component-spacing-md; }
  main [data-component='BannerArticle']:last-child                            { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* BANNER - FEATURE */
[data-component='BannerFeature'] + [data-component='Accordion']               { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='Asset5050']               { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='AssetImage']              { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='AssetVideo']              { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='Banner']                  { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='CardStack']               { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='Carousel']                { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='Columns']                 { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='EmailCapture']            { margin-top: $component-spacing-zero; }
[data-component='BannerFeature'] + [data-component='Feed']                    { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='Form']                    { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='RichText']                { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='Statement']               { margin-top: $component-spacing-sm; }
[data-component='BannerFeature'] + [data-component='StatementWithImage']      { margin-top: $component-spacing-sm; }

[data-component='BannerFeature'] + [data-component='SubNavigation']           { margin-top: $component-spacing-sm; }
main [data-component='BannerFeature']:last-child                              { margin-bottom: $component-spacing-sm; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='BannerFeature'] + [data-component='Accordion']             { margin-top: $component-spacing-zero; }
  [data-component='BannerFeature'] + [data-component='Asset5050']             { margin-top: $component-spacing-zero; }
  [data-component='BannerFeature'] + [data-component='AssetImage']            { margin-top: $component-spacing-zero; }
  [data-component='BannerFeature'] + [data-component='AssetVideo']            { margin-top: $component-spacing-zero; }
  [data-component='BannerFeature'] + [data-component='Banner']                { margin-top: $component-spacing-zero; }
  [data-component='BannerFeature'] + [data-component='Feed']                  { margin-top: $component-spacing-xl; }
  [data-component='BannerFeature'] + [data-component='Form']                  { margin-top: $component-spacing-lg; }
  [data-component='BannerFeature'] + [data-component='Statement']             { margin-top: $component-spacing-zero; }
  [data-component='BannerFeature'] + [data-component='StatementWithImage']    { margin-top: $component-spacing-zero; }
  [data-component='BannerFeature'] + [data-component='SubNavigation']         { margin-top: $component-spacing-zero; }
  main [data-component='BannerFeature']:last-child                            { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule
}

/* BANNER - FULL WIDTH */
[data-component='BannerFullWidth'] + [data-component='EmailCapture']          { margin-top: $component-spacing-sm; }
[data-component='BannerFullWidth'] + [data-component='Feed']                  { margin-top: $component-spacing-sm; }
main [data-component='BannerFullWidth']:last-child                            { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='BannerFullWidth'] + [data-component='Accordion']           { margin-top: $component-spacing-lg; }
  [data-component='BannerFullWidth'] + [data-component='Asset5050']           { margin-top: $component-spacing-lg; }
  [data-component='BannerFullWidth'] + [data-component='AssetImage']          { margin-top: $component-spacing-xxl; }
  [data-component='BannerFullWidth'] + [data-component='AssetVideo']          { margin-top: $component-spacing-xxl; }
  [data-component='BannerFullWidth'] + [data-component='Banner']              { margin-top: $component-spacing-lg; }
  [data-component='BannerFullWidth'] + [data-component='CardStack']           { margin-top: $component-spacing-xxl; }
  [data-component='BannerFullWidth'] + [data-component='Carousel']            { margin-top: $component-spacing-xxl; }
  [data-component='BannerFullWidth'] + [data-component='Columns']             { margin-top: $component-spacing-lg; }
  [data-component='BannerFullWidth'] + [data-component='Feed']                { margin-top: $component-spacing-xl; }
  [data-component='BannerFullWidth'] + [data-component='Form']                { margin-top: $component-spacing-xxl; }
  [data-component='BannerFullWidth'] + [data-component='RichText']            { margin-top: $component-spacing-lg; }
  [data-component='BannerFullWidth'] + [data-component='Statement']           { margin-top: $component-spacing-zero; }
  [data-component='BannerFullWidth'] + [data-component='StatementWithImage']  { margin-top: $component-spacing-zero; }

  main [data-component='BannerFullWidth']:last-child                          { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* BANNER - WITH CARD */
[data-component='BannerWithCard'] + [data-component='EmailCapture']   { margin-top: $component-spacing-sm; }
[data-component='BannerWithCard'] + [data-component='Feed']           { margin-top: $component-spacing-sm; }
main [data-component='BannerWithCard']:last-child                     { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='BannerWithCard'] + [data-component='AssetImage']   { margin-top: $component-spacing-xxl; }
  [data-component='BannerWithCard'] + [data-component='AssetVideo']   { margin-top: $component-spacing-xxl; }
  [data-component='BannerWithCard'] + [data-component='Banner']       { margin-top: $component-spacing-lg; }
  [data-component='BannerWithCard'] + [data-component='CardStack']    { margin-top: $component-spacing-xxl; }
  [data-component='BannerWithCard'] + [data-component='Carousel']     { margin-top: $component-spacing-xxl; }
  [data-component='BannerWithCard'] + [data-component='Columns']      { margin-top: $component-spacing-lg; }
  [data-component='BannerWithCard'] + [data-component='Feed']         { margin-top: $component-spacing-xl; }
  [data-component='BannerWithCard'] + [data-component='Form']         { margin-top: $component-spacing-xxl; }
  [data-component='BannerWithCard'] + [data-component='RichText']     { margin-top: $component-spacing-lg; }
  [data-component='BannerWithCard'] + [data-component='Statement']    { margin-top: $component-spacing-zero; }
  main [data-component='BannerWithCard']:last-child                   { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* CARD STACK */
[data-component='CardStack'] + [data-component='Accordion']                   { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='Asset5050']                   { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='AssetImage']                  { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='AssetVideo']                  { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='Banner']                      { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='CardStack']                   { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='Carousel']                    { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='Columns']                     { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='EmailCapture']                { margin-top: $component-spacing-md; }
[data-component='CardStack'] + [data-component='Form']                        { margin-top: $component-spacing-xxxl; }
[data-component='CardStack'] + [data-component='RichText']                    { margin-top: $component-spacing-xxxl; }
main [data-component='CardStack']:last-child                                  { margin-bottom: $component-spacing-xxxl; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='CardStack'] + [data-component='Accordion']                 { margin-top: $component-spacing-lg; }
  [data-component='CardStack'] + [data-component='Asset5050']                 { margin-top: $component-spacing-lg; }
  [data-component='CardStack'] + [data-component='AssetImage']                { margin-top: $component-spacing-xxl; }
  [data-component='CardStack'] + [data-component='AssetVideo']                { margin-top: $component-spacing-xxl; }
  [data-component='CardStack'] + [data-component='Banner']                    { margin-top: $component-spacing-lg; }
  [data-component='CardStack'] + [data-component='Columns']                   { margin-top: $component-spacing-xxl; }
  [data-component='CardStack'] + [data-component='EmailCapture']              { margin-top: $component-spacing-lg; }
  [data-component='CardStack'] + [data-component='RichText']                  { margin-top: $component-spacing-xxl; }
  [data-component='CardStack'] + [data-component='Statement']                 { margin-top: $component-spacing-xxl; }
  [data-component='CardStack'] + [data-component='StatementWithImage']        { margin-top: $component-spacing-xxl; }

  main [data-component='CardStack']:last-child                                { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* CAROUSEL */
[data-component='Carousel'] + [data-component='CardStack']                    { margin-top: $component-spacing-xl; }
[data-component='Carousel'] + [data-component='Carousel']                     { margin-top: $component-spacing-xl; }
[data-component='Carousel'] + [data-component='Columns']                      { margin-top: $component-spacing-xl; }
[data-component='Carousel'] + [data-component='EmailCapture']                 { margin-top: $component-spacing-sm; }
[data-component='Carousel'] + [data-component='Form']                         { margin-top: $component-spacing-xl; }
[data-component='Carousel'] + [data-component='RichText']                     { margin-top: $component-spacing-xl; }
main [data-component='Carousel']:last-child                                   { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='Carousel'] + [data-component='Accordion']                  { margin-top: $component-spacing-xl; }
  [data-component='Carousel'] + [data-component='Asset5050']                  { margin-top: $component-spacing-xl; }
  [data-component='Carousel'] + [data-component='AssetImage']                 { margin-top: $component-spacing-xxl; }
  [data-component='Carousel'] + [data-component='AssetVideo']                 { margin-top: $component-spacing-xxl; }
  [data-component='Carousel'] + [data-component='Banner']                     { margin-top: $component-spacing-xl; }
  [data-component='Carousel'] + [data-component='CardStack']                  { margin-top: $component-spacing-xxl; }
  [data-component='Carousel'] + [data-component='Carousel']                   { margin-top: $component-spacing-xxl; }
  [data-component='Carousel'] + [data-component='Columns']                    { margin-top: $component-spacing-xxl; }
  [data-component='Carousel'] + [data-component='EmailCapture']               { margin-top: $component-spacing-md; }
  [data-component='Carousel'] + [data-component='Form']                       { margin-top: $component-spacing-xxl; }
  [data-component='Carousel'] + [data-component='RichText']                   { margin-top: $component-spacing-xxl; }
  [data-component='Carousel'] + [data-component='Statement']                  { margin-top: $component-spacing-xl; }
  [data-component='Carousel'] + [data-component='StatementWithImage']         { margin-top: $component-spacing-xl; }

  main [data-component='Carousel']:last-child                                 { margin-bottom: $component-spacing-xxl; } // [data-component='Footer'] rule
}

/* COLUMNS */
[data-component='Columns'] + [data-component='Accordion']                     { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='Asset5050']                     { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='AssetImage']                    { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='AssetVideo']                    { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='Banner']                        { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='CardStack']                     { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='Carousel']                      { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='Columns']                       { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='EmailCapture']                  { margin-top: $component-spacing-zero; }
[data-component='Columns'] + [data-component='Form']                          { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='RichText']                      { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='Statement']                     { margin-top: $component-spacing-sm; }
[data-component='Columns'] + [data-component='StatementWithImage']            { margin-top: $component-spacing-sm; }

main [data-component='Columns']:last-child                                    { margin-bottom: $component-spacing-sm; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='Columns'] + [data-component='Accordion']                   { margin-top: $component-spacing-zero; }
  [data-component='Columns'] + [data-component='Asset5050']                   { margin-top: $component-spacing-zero; }
  [data-component='Columns'] + [data-component='AssetImage']                  { margin-top: $component-spacing-md; }
  [data-component='Columns'] + [data-component='AssetVideo']                  { margin-top: $component-spacing-md; }
  [data-component='Columns'] + [data-component='Banner']                      { margin-top: $component-spacing-zero; }
  [data-component='Columns'] + [data-component='CardStack']                   { margin-top: $component-spacing-lg; }
  [data-component='Columns'] + [data-component='Carousel']                    { margin-top: $component-spacing-lg; }
  [data-component='Columns'] + [data-component='Columns']                     { margin-top: $component-spacing-lg; }
  [data-component='Columns'] + [data-component='EmailCapture']                { margin-top: $component-spacing-sm; }
  [data-component='Columns'] + [data-component='Form']                        { margin-top: $component-spacing-lg; }
  [data-component='Columns'] + [data-component='RichText']                    { margin-top: $component-spacing-lg; }
}

/* Columns Child Component Spacing */*
[data-component='Columns'] [data-component='AssetImage']:not(:first-child) {
  margin-top: $component-spacing-lg!important;

  @include breakpoint(medium) {
    margin-top: $component-spacing-lg!important;
  }
}

[data-component='Columns'] [data-component='AssetVideo']:not(:first-child) {
  margin-top: $component-spacing-lg!important;

  @include breakpoint(medium) {
    margin-top: $component-spacing-lg!important;
  }
}

[data-component='Columns'] [data-component='RichText']:not(:first-child) {
  margin-top: $component-spacing-lg!important;

  @include breakpoint(medium) {
    margin-top: $component-spacing-lg!important;
  }
}

/* EMAIL CAPTURE */
[data-component='EmailCapture'] + [data-component='CardStack']                { margin-top: $component-spacing-xl; }
[data-component='EmailCapture'] + [data-component='Carousel']                 { margin-top: $component-spacing-xl; }
[data-component='EmailCapture'] + [data-component='Columns']                  { margin-top: $component-spacing-xl; }
[data-component='EmailCapture'] + [data-component='Form']                     { margin-top: $component-spacing-xl; }
[data-component='EmailCapture'] + [data-component='RichText']                 { margin-top: $component-spacing-zero; }
[data-component='EmailCapture'] + [data-component='Statement']                { margin-top: $component-spacing-zero; }
[data-component='EmailCapture'] + [data-component='StatementWithImage']       { margin-top: $component-spacing-zero; }

main [data-component='EmailCapture']:last-child                               { margin-bottom: $component-spacing-zero; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='EmailCapture'] + [data-component='Form']                   { margin-top: $component-spacing-xxl; }
  [data-component='EmailCapture'] + [data-component='RichText']               { margin-top: $component-spacing-xl; }
}

/* FEED */
main [data-component='Feed']:last-child                                       { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

/* FORM */
[data-component='Form'] + [data-component='Statement']                        { margin-top: $component-spacing-zero; }
[data-component='Form'] + [data-component='StatementWithImage']               { margin-top: $component-spacing-zero; }
main [data-component='Form']:last-child                                       { margin-bottom: $component-spacing-zero; } // [data-component='Footer'] rule

/* RICH TEXT */
[data-component='RichText'] + [data-component='EmailCapture']                 { margin-top: $component-spacing-sm; }
main [data-component='RichText']:last-child                                   { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='RichText'] + [data-component='AssetImage']                 { margin-top: $component-spacing-xl; }
  [data-component='RichText'] + [data-component='AssetVideo']                 { margin-top: $component-spacing-xl; }
  [data-component='RichText'] + [data-component='CardStack']                  { margin-top: $component-spacing-xl; }
  [data-component='RichText'] + [data-component='Carousel']                   { margin-top: $component-spacing-xl; }
  [data-component='RichText'] + [data-component='Form']                       { margin-top: $component-spacing-xl; }
  [data-component='RichText'] + [data-component='Statement']                  { margin-top: $component-spacing-md; }
  [data-component='RichText'] + [data-component='StatementWithImage']         { margin-top: $component-spacing-md; }
}

/* SOCIAL SHARING */
[data-component] + [data-component='SocialSharing']                           { margin-top: $component-spacing-sm; }
main [data-component='SocialSharing']:last-child                              { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

/* STATEMENT */
[data-component='Statement'] + [data-component='EmailCapture']                { margin-top: $component-spacing-sm; }
[data-component='Statement'] + [data-component='Statement']                   { margin-top: $component-spacing-zero; }
main [data-component='Statement']:last-child                                  { margin-bottom: $component-spacing-zero; } // [data-component='Footer'] rule

/* STATEMENT WITH IMAGE*/
[data-component='StatementWithImage'] + [data-component='EmailCapture']       { margin-top: $component-spacing-sm; }
[data-component='StatementWithImage'] + [data-component='StatementWithImage'] { margin-top: $component-spacing-zero; }
main [data-component='StatementWithImage']:last-child                         { margin-bottom: $component-spacing-zero; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='Statement'] + [data-component='CardStack']                 { margin-top: $component-spacing-xxl; }
  [data-component='Statement'] + [data-component='Carousel']                  { margin-top: $component-spacing-xxl; }
  [data-component='Statement'] + [data-component='Columns']                   { margin-top: $component-spacing-xl; }
  [data-component='Statement'] + [data-component='EmailCapture']              { margin-top: $component-spacing-md; }
  [data-component='Statement'] + [data-component='Form']                      { margin-top: $component-spacing-xxl; }
  [data-component='Statement'] + [data-component='RichText']                  { margin-top: $component-spacing-xl; }
}

/* SUB-NAVIGATION */
main [data-component='SubNavigation']:last-child                              { margin-bottom: $component-spacing-lg; } // [data-component='Footer'] rule

@include breakpoint(medium) {
  [data-component='SubNavigation'] + [data-component='CardStack']             { margin-top: $component-spacing-xxl; }
  [data-component='SubNavigation'] + [data-component='Carousel']              { margin-top: $component-spacing-xxl; }
  [data-component='SubNavigation'] + [data-component='Columns']               { margin-top: $component-spacing-xxl; }
  [data-component='SubNavigation'] + [data-component='Form']                  { margin-top: $component-spacing-xxl; }
  [data-component='SubNavigation'] + [data-component='RichText']              { margin-top: $component-spacing-xxl; }
}

/* Override Spacing Above Component
 * This is specified within the Style Modifier system on supporting Components, we use the !important flag to ensure the override
 * and they only apply to larger screens ( > 768 )
 */

@include breakpoint(medium) {
  .osa-inherit {
    margin-top: inherit;
  }

  .osa-zero {
    margin-top: 0!important;
  }

  .osa-small {
    margin-top: $component-spacing-sm!important;
  }

  .osa-medium {
    margin-top: $component-spacing-md!important;
  }

  .osa-large {
    margin-top: $component-spacing-lg!important;
  }

  .osa-x-large {
    margin-top: $component-spacing-xl!important;
  }

  .osa-xx-large {
    margin-top: $component-spacing-xxl!important;
  }

  .osa-xxx-large {
    margin-top: $component-spacing-xxxl!important;
  }


  /* Override Spacing Below Component
  * This is specified within the Style Modifier system on supporting Components, we use the !important flag to ensure the override
  * and they only apply to larger screens ( > 768 )
  */
  .osb-inherit {
    padding-bottom: inherit;
  }

  .osb-zero {
    padding-bottom: 0!important;
  }

  .osb-small {
    padding-bottom: $component-spacing-sm!important;
  }

  .osb-medium {
    padding-bottom: $component-spacing-md!important;
  }

  .osb-large {
    padding-bottom: $component-spacing-lg!important;
  }

  .osb-x-large {
    padding-bottom: $component-spacing-xl!important;
  }

  .osb-xx-large {
    padding-bottom: $component-spacing-xxl!important;
  }

  .osb-xxx-large {
    padding-bottom: $component-spacing-xxxl!important;
  }
}
