@use 'styles/abstracts' as *;

.primary {
  display: flex;
  flex-direction: column;
}

.secondary {
  display: flex;
  flex-direction: row;
}

.carousel-wrapper-primary {
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  width: 100%;
  text-align: center;
}

.carousel-wrapper-secondary {
  display: grid;
  grid-template-rows: 20px 1fr 20px;
  width: 100%;
  text-align: center;
}

.prev {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  background-color: lightgray;
  outline: none;
}

.next {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  background-color: lightgray;
  outline: none;
}

.pause {
  cursor: pointer;
  border: none;
  background-color: lightgray;
  outline: none;
  min-width: 30px;
  min-height: 25px;
}

.thumbs-container-primary {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  overflow: hidden;
}

.thumbs-container-secondary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  overflow: hidden;
}

.thumb-primary {
  width: 100px;
  height: 5px;
  margin: 1px;
  cursor: pointer;
  border: none;
  background-color: lightgray;
  outline: none;
  border-radius: 8px;

  &:hover, &:focus {
    background-color: lightcoral;
  }
}

.thumb-secondary {
  padding: 0;
  width: 5px;
  height: 100px;
  margin: 1px;
  cursor: pointer;
  border: none;
  background-color: lightgray;
  outline: none;
  border-radius: 8px;

  &:hover, &:focus {
    background-color: lightcoral;
  }
}

.thumb-active {
  background-color: red;
}
