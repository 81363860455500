@use 'styles/abstracts' as *;

.subnav {
  @include grid-column(1, span 12);

  align-content: space-between;
  align-items: flex-start;
  background: $medium-gray;
  border-radius: 0 0 rem(35) rem(35);
  box-shadow: 0 rem(12) rem(24) rgb(9 7 37 / 7%);
  column-gap: rem(70);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: rem(-30);
  padding: rem(30) rem(40) 0;
  width: 100%;
  position: absolute;
  top: rem(90);

  li {
    border-bottom: rem(2) solid transparent;

    a {
      padding: rem(18) 0 rem(16);
    }
  }

  li.selected {
    border-bottom: rem(2) solid $black;
  }

  li:first-child {
    a {
      padding-left: 0;
    }
  }
}
