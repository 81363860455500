@use 'styles/abstracts' as *;

.primary {
  $pri: &;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding-top: rem(9);

  .wrapper {
    position: relative;
    width: 100%;
    padding: rem(20) rem(32);
    border-radius: rem(30);
    border: 1px solid $form-stroke;
    background-color: $white;
  
    ::-webkit-scrollbar {
      width: rem(6);
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $form-stroke;
      border-radius: rem(20);
      border: 1px solid $form-stroke;
    }
    ::-webkit-resizer {
      display: none;
    }

    &:focus-within {
      border-color: $black;
    }
  }

  .dirty {
    border-color: $black !important;
  }

  .label {
    color: $form-label;
    position: absolute;
    top: rem(20);
    left: rem(30);
    transform-origin: 0 0;
    transition: transform 0.1s ease-in-out;
  }

  .input {
    width: 100%;
    font-weight: 350;
    font-size: 18px;
    border: 0;
    background-color: $white;
    scrollbar-width: thin;
    scrollbar-color: $form-stroke transparent;
    outline: none;

    &::placeholder {
      color: transparent;
    }

    &:focus + .label, &:not(:placeholder-shown) + .label {
      transform: translate(rem(11), rem(-30));
      max-height: 20px;
      border-radius: 40px;
      background-color: $page-base;
      padding: 0 8px;

      span {
        font-size: 12px;
        color: $black;
      }
    }

    &:focus + .label-success, &:not(:placeholder-shown) + .label-success {
      background-color: $success-background !important;
    }

    &:focus + .label-error, &:not(:placeholder-shown) + .label-error {
      background-color: $error-background !important;
    }
  }

  .error {
    padding: rem(18) rem(32) 0 rem(32);
  }

  .wrapper-error {
    border: 1px solid $error-stroke;
  }

  .wrapper-success {
    border: 1px solid $success-stroke;
  }
}

.secondary {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding-top: rem(9);

  .wrapper {
    position: relative;
    width: 100%;
    padding-top: rem(20);
    border: 0;
    color: $light-gray;
    border-bottom: 1px solid rgb(255, 255, 255, 0.1);
    background-color: transparent;
  
    ::-webkit-scrollbar {
      width: rem(6);
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $form-stroke;
      border-radius: rem(20);
      border: 1px solid $form-stroke;
    }
    ::-webkit-resizer {
      display: none;
    }

    &:focus-within {
      border-color: $white;
    }
  }

  .label {
    color: $dark-gray;
    position: absolute;
    top: rem(20);
    left: rem(0);
    transform-origin: 0 0;
    transition: transform 0.1s ease-in-out;
    width: 100%;
  }

  .input {
    width: 100%;
    font-weight: 350;
    font-size: 18px;
    border: 0;
    color: $light-gray;
    background-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: $form-stroke transparent;
    outline: none;

    &::placeholder {
      color: transparent;
    }

    &:focus + .label, &:not(:placeholder-shown) + .label {
      transform: translate(rem(-6), rem(-29));
      visibility: hidden;
      opacity: 0;
    }

    &:focus + .label-error, &:not(:placeholder-shown) + .label-error {
      background-color: $error-background !important;

      span {
        color: $error-stroke !important;
      }
    }
  }
}
