@use 'styles/abstracts' as *;

.video {
  .background {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    z-index: 100;

    @include breakpoint(medium) {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .windowed {
    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      visibility: visible;
      width: 100%;
    }
  }

  .fullscreen {
    height: 100%;
    z-index: 101;

    @include breakpoint(medium) {
      max-width: rem($content-width-wide);
      width: calc(95% - rem(80));
      height: 100%;
      max-height: 95vh;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .wrapper {
      position: relative;
      transform: translate(0%, 100%);
      padding-bottom: 56.25%;

      @include breakpoint(medium) {
        position: relative;
        padding-bottom: 56.25%;
        transform: translate(0%, 0%);
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: visible;
      }
    }

    button {
      position: absolute;
      top: 15%;
      right: rem(0);
      width: rem(50);

      @include breakpoint(medium) {
        position: absolute;
        top: rem(0);
        right: rem(-50);
        width: rem(50);
      }

      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}

.aspect-ratio {
  content: "";
  display: block;
  height: 0;
  width: 100%;
}

.aspect-ratio-1::before {
  @extend .aspect-ratio;
  max-height: calc(100% / 1);
}

.aspect-ratio-2-3::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (2/3));
}

.aspect-ratio-3-2::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (3/2));
}

.aspect-ratio-4-3::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (4/3));
}

.aspect-ratio-9-16::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (9/16));
}

.aspect-ratio-16-9::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (16/9));
}

.aspect-ratio-banner::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (560/445));
}

.aspect-ratio-banner-featured::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (750/800));
}

.aspect-ratio-banner-full-width::before {
  @extend .aspect-ratio;
  max-height: calc(100% / (2000/935));
}

.aspect-ratio-original {
  position: relative;
}
