@use 'styles/abstracts' as *;

.hidden {
  display: none !important;
}
.descLabel{
  color: $form-label;
  span {
    font-size: 15px;
    font-weight: 350;
  }
}

.primary {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  padding-top: rem(9);

  .label {
    color: $form-label;
    position: absolute;
    top: rem(30);
    left: rem(32);
    transform-origin: 0 0;
    transition: transform 0.1s ease-in-out;
  }

  input:focus {
    border-color: $black;
  }

  .input {
    padding: rem(20) rem(32);
    max-height: rem(60);
    width: 100%;
    border-radius: rem(50);
    border: 1px solid $form-stroke;
    background-color: $white;
    font-weight: 350;
    font-size: 18px;
    outline: none;

    &::placeholder {
      color: transparent;
    }

    &:focus, &:focus-visible, &:active {
      border-color: $black;
    }

    &:focus + .label, &:not(:placeholder-shown) + .label {
      transform: translate(rem(11), rem(-29));
      max-height: 20px;
      border-radius: 40px;
      background-color: $page-base;
      padding: 0 8px;

      span {
        font-size: 12px;
        color: $black;
      }
    }

    &:not(:placeholder-shown) {
      border-color: $black;
    }

    &:focus + .label-success, &:not(:placeholder-shown) + .label-success {
      background-color: $success-background !important;
    }

    &:focus + .label-error, &:not(:placeholder-shown) + .label-error {
      background-color: $error-background !important;
    }
  }

  .error {
    padding: rem(18) rem(32) 0 rem(32);
  }

  .input-error {
    border: 1px solid $error-stroke !important;
  }

  .input-success {
    border: 1px solid $success-stroke !important;
  }
}

.secondary {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  padding-top: rem(9);

  .label {
    color: $dark-gray;
    position: absolute;
    top: rem(28);
    left: rem(0);
    transform-origin: 0 0;
    transition: transform 0.1s ease-in-out;
    width: 100%;
  }

  input:focus {
    border-color: $black;
  }

  .input {
    padding-top: rem(20);
    max-height: rem(50);
    width: 100%;
    border: 0;
    color: $light-gray;
    border-bottom: rem(1) solid rgb(255, 255, 255, 0.1);
    background-color: transparent;
    font-weight: 350;
    font-size: 16px;
    outline: none;

    &::placeholder {
      color: transparent;
    }

    &:focus, &:focus-visible, &:active {
      border-color: $white;
    }

    &:focus + .label, &:not(:placeholder-shown) + .label {
      transform: translate(rem(-6), rem(-29));
      visibility: hidden;
      opacity: 0;
    }

    &:focus + .label-error, &:not(:placeholder-shown) + .label-error {
      background-color: $error-background !important;

      span {
        color: $error-stroke !important;
      }
    }
  }

  .error {
    padding-top: rem(10);
  }

  .input-error {
    border-bottom: rem(1) solid $error-stroke;
  }
}
