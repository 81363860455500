@use 'styles/abstracts' as *;

.primary {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  .dirty {
    border: 1px solid $black;
  }

  .label {
    color: $black;
    position: absolute;
    top: rem(15);
    left: rem(15);
    transform-origin: 0 0;
    transition: transform 0.1s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  .input {
    padding: rem(14) rem(22) rem(16) rem(10);
    max-height: rem(60);
    width: 100%;
    // border-radius: rem(50);
    border: 1.5px solid $black;
    background-color: $white;
    font-weight: 350;
    font-size: 18px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: 
      url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxOCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUw5IDlMMTcgMSIgc3Ryb2tlPSIjMkYzQTQxIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    background-position: calc(100% - rem(30)) center;
    background-size: rem(18);
    background-repeat: no-repeat;

    &:focus, &:focus-visible {
      outline: none;
      border-color: 1.5px solid $black;
    }

    &:focus + .label, + .label {
      transform: translate(rem(11), rem(-29));
      max-height: 20px;
      border-radius: 40px;
      background-color: $page-base;
      padding: 0 8px;

      span {
        font-size: 12px;
        color: $black;
      }
    }

    &:focus + .label-success, &:not(:placeholder-shown) + .label-success {
      background-color: $success-background !important;
    }

    &:focus + .label-error, &:not([currentvalue=""]):valid + .label-error {
      background-color: $error-background !important;
    }
  }

  .error {
    padding: rem(18) rem(32) 0 rem(32);
  }

  .input-dirty {
    border: 1.5px solid $black;
  }

  .input-error {
    border: 1.5px solid $black;
  }

  .input-success {
    border: 1.5px solid $black;
  }
}

.secondary {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}

.tertiary {
  .dirty {
    border: 1.5px solid $black;
  }

  .input {
    padding: rem(14) rem(52) rem(13) rem(32);
    max-height: rem(60);
    width: 100%;
    border-radius: rem(50);
    border: 1px solid $form-stroke;
    background-color: $white;
    font-weight: 350;
    font-size: 18px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: 
      url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxOCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUw5IDlMMTcgMSIgc3Ryb2tlPSIjMkYzQTQxIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    background-position: calc(100% - rem(30)) center;
    background-size: rem(18);
    background-repeat: no-repeat;

    &:focus, &:focus-visible {
      outline: none;
      border-color: $black;
    }
  }

  .error {
    padding: rem(18) rem(32) 0 rem(32);
  }

  .input-dirty {
    border: 1px solid $black;
  }

  .input-error {
    border: 1px solid $error-stroke;
  }

  .input-success {
    border: 1px solid $success-stroke;
  }
}
