@use '../breakpoints' as *;
@use '../sizing' as *;
@use './px-to-rems' as *;

@mixin grid {
  display: grid;
  
  @include breakpoint(small-max) {
    grid-template-columns: repeat(6, 1fr);
    column-gap: rem(6);
  }
  
  @include breakpoint(medium) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: rem(20);
  }
  
  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: rem(40);
  }
}

@mixin grid--component-container {
  margin-inline: rem(26);
  
  @include breakpoint(xlarge) {
    margin-inline: rem(0);
    max-width: rem($content-width);
  }
}

@mixin grid-column($start, $end) {
  grid-column: $start / #{'' + $end};
}

@mixin grid-row($start, $end) {
  grid-row: $start / #{'' + $end};
}
