@use 'styles/abstracts' as *;

.base {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  
  background: transparent;
  border: rem(1) solid transparent;
  color: $black;

  svg {
    margin-top: rem(-2);
    max-width: rem(20);
    min-width: rem(20);
  }

  svg + span {
    margin-left: rem(8);
  }

  span + svg {
    margin-left: rem(8);
  }

  &:hover {
    cursor: pointer;
  }
}
