@use 'styles/abstracts' as *;

.section-list-header,
.section-list-link {
  margin: 0 0 rem(8) 0;
  padding: 0 rem(16);

  a {
    padding: rem(12) 0;
    text-transform: uppercase !important;

    &:hover {
      span {
        color: $red;
      }
    }
  }
}

.section-list-header {
  > ul {
    visibility: hidden;
    opacity: 0;
    z-index: 11;
    transition: opacity 0.5s ease-in;
  }
  &.isHovered {
    > ul {
      visibility: visible;
      opacity: 1;
      z-index: 12;
    }
  }
}

.highlighted {
  span {
    font-weight: 700 !important;
    color: $near-black;
  }

  svg {
    path {
      stroke: $near-black;
    }
  }
}

.isHovered {
  a:hover {
    color: $near-black;

    span {
      color: $red;
    }

    svg path {
      stroke: $red;
    }
  }
}
