@use './colors' as *;
@use './mixins/px-to-rems' as *;

.common-description {
  em, i {
    font-style: italic;
  }

  b, strong {
    font-weight: bold;
  }

  a,
  a:active,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: inherit;
    text-decoration: none;
  }
}
      
.common-icon-arrow-animated-base {
  position: relative;
  margin-top: rem(-5);
  margin-left: rem(10);
  width: rem(10);
  height: rem(2);
  background: $red;
  border: rem(1) solid $red;
  transition: all .2s ease;
}
  
.common-icon-arrow-animated-head {
  top: rem(1);
  right: rem(-3);
  
  &,
  &:after {
    position: absolute; 
    width: rem(6.5);
    border: rem(1) solid $red;
    transform: rotate(-45deg);
  }
  
  &:after {
    content: '';
    top: rem(-4);
    right: rem(-4);
    border-color: $red;
    transform: rotate(90deg);
  }
}

.common-icon-arrow-animated-active {
  width: rem(20);
}

/*
 * CSS styles applied only to Safari browsers 11+
 */
 @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .common-icon-arrow-animated-base {
      margin-top: rem(0);
    }
  }
}
