@use 'styles/abstracts' as *;

body {
  background-color: $page-base;
  min-width: rem(280);
}

// Accessibility best practice to hide labels and helper text
// <span className='visually-hidden'>about this subject matter.</span>
.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Default font family applied to website
.fonts-loaded body {
  font-family: 'HCo Gotham SSm', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 350;
}
//make sure coral icons do not hold space when injected below the footer
svg._coral-Icon-collection { display:none; }
