.image {
  height: 100%;
  max-width: 100%;
  position: relative;
}

.image img {
  height: 100%;
  left: 0;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.aspect-ratio {
  content: "";
  display: block;
  height: 0;
  width: 100%;
}

.aspect-ratio-1::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / 1);
}

.aspect-ratio-2-3::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (2/3));
}

.aspect-ratio-3-2::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (3/2));
}

.aspect-ratio-4-3::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (4/3));
}

.aspect-ratio-9-16::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (9/16));
}

.aspect-ratio-16-9::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (16/9));
}

.aspect-ratio-banner::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (560/445));
}

.aspect-ratio-banner-featured::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (750/800));
}

.aspect-ratio-banner-full-width::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (2000/935));
}

.aspect-ratio-card::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (360/334));
}

.aspect-ratio-card-feed::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (360/313));
}

.aspect-ratio-card-featured::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (758/536));
}

.aspect-ratio-card-wide::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (560/361));
}

.aspect-ratio-carousel-card::before {
  @extend .aspect-ratio;
  padding-bottom: calc(100% / (360/290));
}

.aspect-ratio-original img {
  position: relative;
}
