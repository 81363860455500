@use 'styles/abstracts' as *;

.list {
  $nul: &;

  flex: 0 0 rem(150);
  max-width: rem(300);
  min-width: rem(150);
  padding: $element-v-spacing-lg 0 0;

  .header {
    padding: $element-v-spacing-md $element-spacing-md;
    text-transform: uppercase;
  }

  .links {
    padding: 0 0 $element-v-spacing-md;

    li {
      padding: 0 $element-spacing-md $element-v-spacing-md $element-spacing-xl;
      text-transform: uppercase;

      a.active span {
        color: $red;
        font-weight: 700;
      }
    }
  }
}
