@use 'styles/abstracts' as *;

.columns {
  $col: &;

  margin: 0 auto;
  max-width: rem($content-width-max);
  position: relative;

  .container {
    display: flex;
    justify-content: center;
    position: relative;

    .wrapper {
      @include grid;
      @include grid--component-container;

      max-width: rem($content-width);
      position: relative;
      width: 100%;

      /* Image Component Overrides */
      [data-component='AssetImage'] {
        //[class*='AssetImage_wrapper']
        > div > div {
          margin-inline: 0;
        }
      }

      /* RichText Component Overrides */
      [data-component='RichText'] {
        //[class*='RichText_wrapper']
        > div > div {
          margin-inline: 0;
        }

        //[class*='RichText_content']
        > div > div > div {
          grid-column:1/span 12;
        }
      }

      /* Video Component Overrides */
      [data-component='AssetVideo'] {
        //[class*='AssetVideo_wrapper']
        > div > div {
          margin-inline: 0;
        }
      }

    }
  }
}

.columns-1 {
  .column-1 {
    @include grid-column(1, span 6);
    overflow: hidden;
  }

  @include breakpoint(medium) {
    .column-1 {
      @include grid-column(1, span 12);
      overflow: hidden;
    }
  }
}

.columns-2 {
  .column-1 {
    @include grid-column(1, span 6);
    overflow: hidden;
  }

  .column-2 {
    @include grid-column(1, span 6);
    overflow: hidden;
  }

  @include breakpoint(medium) {
    .column-1 {
      @include grid-column(1, span 6);
      overflow: hidden;
    }

    .column-2 {
      @include grid-column(7, span 6);
      overflow: hidden;
    }
  }
}

.columns-3 {
  .column-1 {
    @include grid-column(1, span 6);
    overflow: hidden;
  }

  .column-2 {
    @include grid-column(1, span 6);
    overflow: hidden;
  }

  .column-3 {
    @include grid-column(1, span 6);
    overflow: hidden;
  }

  @include breakpoint(medium) {
    .column-1 {
      @include grid-column(1, span 4);
      overflow: hidden;
    }

    .column-2 {
      @include grid-column(5, span 4);
      overflow: hidden;
    }

    .column-3 {
      @include grid-column(9, span 4);
      overflow: hidden;
    }
  }
}
