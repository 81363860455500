@use 'styles/abstracts' as *;

.section {
  $sect: &;

  @include grid-column(1, span 12);

  grid-row: 1;
  opacity: 0;
  overflow: visible;
  position: relative;
  transition: opacity 0.5s linear;
  z-index: 11;

  .section-content {
    padding: 0 rem(55) rem(55);

    .section-list {
      width: 100%;

      a {
        color: $near-black;
        justify-content: space-between;
        padding: rem(10) 0;

        &:active {
          color: $medium-blue;

          svg {
            stroke: $medium-blue;
          }
        }
      }

      .section-overview {
        align-items: center;
        display: inline-flex;
        justify-content: space-between;
        margin: 0 rem(32) rem(32) 0;
        min-width: calc(25% + rem(30));
        background-color: #e6e6e6;
        border-radius: rem(40);
        padding: 0 rem(40);

        .highlighted {
          span {
            font-weight: 700;
          }
        }

        a {
          align-items: center;
          color: $black;
          display: flex;
          padding: rem(12) rem(32) rem(12) rem(16);
          text-transform: uppercase;

          svg {
            margin-top: rem(-1);
            min-height: rem(24);
            min-width: rem(24);

            path {
              stroke: $red;
            }
          }

          &:hover {
            color: $red;

            svg {
              path {
                stroke: $red;
              }
            }
          }

          &:active {
            color: $medium-blue;

            svg {
              path {
                stroke: $medium-blue;
              }
            }
          }

          div:first-child {
            margin-right: rem(6);
            white-space: nowrap;
          }
        }

        div + div {
          margin-top: rem(1);
        }
      }

      .section-faq {
        position: absolute;
        right: rem(55);
        top: 0;

        a {
          padding: rem(10);

          svg {
            fill: $red;
            stroke: transparent;
          }

          &:hover {
            color: $red;

            svg {
              fill: $red;
              stroke: transparent;
            }
          }

          &:active {
            color: $medium-blue;

            svg {
              fill: $medium-blue;
              stroke: transparent;
            }
          }
        }
      }

      .section-group-wrapper {
        display: flex;
        justify-content: flex-start;
        position: relative;

        .section-list-group {
          min-width: 25%;
          max-width: 28%;
          ul {
            position: absolute;
            top: 0;
            padding-left: rem(50);
          }
          >li>ul {
            left: 25%;
            width: 25%;
            >li>ul {
              left: 100%;
              width: 95%;
              >li>ul {
                left: 100%;
                width: 100%;
              }

            }

          }
        }
      }
    }

  }
}

.section.isActive {
  opacity: 1;
  z-index: 12;
}