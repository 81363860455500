@use 'styles/abstracts' as *;

.primary {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: row;
  padding: rem(2) 0;

  .label {
    padding-left: rem(10);
    color: $black;
  }
}

input[type="checkbox"] {
  min-width: 13px;
  min-height: 13px;
}

.secondary {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding: rem(2) 0;

  .label {
    padding-right: rem(10);
    color: $black;
  }
}
