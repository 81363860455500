@use 'styles/abstracts' as *;

.form {
  background: inherit;
}

.captcha {
  @include breakpoint(small-max) {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: rem(20);

  .form-elements {
    display: flex;
    flex-direction: column;
    row-gap: rem(20);
  }

  .form-buttons {
    margin-top: rem(9);
  }
}

.simple {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  .form-elements {
    display: inherit;
  }

  .form-buttons {
    margin-left: rem(16);
    margin-top: rem(9);

    button {
      min-height: rem(60);
    }
  }
}

.server-error {
  display: flex;
  flex-direction: column;
  border: none;
  align-items: center;
}

.errors-container {
  display: flex;
  flex-direction: column;
  border: none;
}

.errors-list {
  padding: rem(10);

  li {
    list-style: disc;
    list-style-position: inside;
    color: $red;
  }
}

.captcha {
  padding-top: rem(9);
}

.errors-container-deselector {
  display: none !important;
}
