@use 'styles/abstracts' as *;

.primary {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: row;
  padding: rem(2) 0;


  .label {
    color: $black;
  }

  .desc {
    color: $black;
    padding-top: rem(20);
  }

  

  .links {
    display: block;
    padding-top: rem(30);
    text-align: center;
    a {
      margin-bottom: $element-v-spacing-lg;
      width: 100%;
      button{
        outline: none;
      }
    }

    @include breakpoint(medium) {
      a {
        width: auto;
        button{
          outline: none;
        }
      }

    
    }
  }

  .textLabel {
    padding-left: rem(10);
    padding-right: rem(20);
    ;
    text-align: justify;
  }

  input[type='radio'] {
    accent-color: $red;
 }
 .label-selected{
  color: $red;;
 }
}

.secondary {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding: rem(2) 0;


  .label {
    color: $black;
  }

  .desc {
    color: $black;
    padding-top: rem(20);
  }
  .links {
    display: block;
    padding-top: rem(20);
    
    a {
      margin-bottom: $element-v-spacing-lg;
      width: 100%;
      button{
        outline: none;
      }
    }

    @include breakpoint(medium) {
      a {
        width: auto;
        button{
          outline: none;
        }
      }
    }
  }

  .textLabel {
    padding-left: rem(10);
    padding-right: rem(20);
    ;
    text-align: justify;
  }
  input[type='radio'] {
    accent-color: $red;
 }
}