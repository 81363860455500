@use 'styles/abstracts' as *;

.search {
  $srch: &;

  @include grid-column(1, span 6);

  overflow: hidden;

  @include breakpoint(medium) {
    @include grid-column(1, span 12);
  }

  .formcontainer {
    padding-bottom: $element-v-spacing-lg;
    padding: rem(40) rem(50) rem(100);
    position: relative;
    z-index: 10;

    form > div {
      align-content: stretch;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      width: 100%;
    }

    form > div > div:first-child {
      border-bottom: rem(1) solid #bbb; //$medium-gray; is overwritten because the filter makes it look white
      flex-grow: 12;

      label {
        padding-top: 0;
        position: relative;
      }

      label input {
        // TODO: Typography should not be here
        font-size: rem(24);
        font-weight: 350;
        line-height: rem(27);
        width: 100%;
      }

      input {
        background-color: transparent;
        border-color: transparent;
        border-radius: 0;
        color: $black;
        outline: none;
        padding: rem(20) 0 0;
      }

      input::placeholder {
        color: transparent;
      }
  
      input:focus, input:focus-visible, input:active {
        border-color: transparent;
      }

      label > span {
        background-color: transparent;
        color: $medium-gray;
        left: rem(2);
        padding: 0;
        position: absolute;
        top: rem(-7);

        span {
          // TODO: Typography should not be here
          font-size: rem(24);
          font-weight: 350;
          line-height: rem(27);
        }
      }
      
      label > span:hover {
        cursor: text;
      }

      input:placeholder-shown + span {
        color: $dark-gray;
        opacity: 1;

        span {
          opacity: 1;
        }
      }

      input:not(:placeholder-shown) + span {
        color: $medium-gray;
        opacity: 0;
        transform:scale(0);

        span {
          opacity: 0;
        }
      }
    }

    form > div > div:last-child {
      border-bottom: rem(1) solid #bbb; //$medium-gray; is overwritten because the filter makes it look white
      padding-top: rem(5);

      button {
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: rem(5) 0 0;

        svg {
          max-height: rem(24);
          fill: none;
          stroke: $dark-gray;
          width: rem(24);

          path {
            fill: $black;
          }
        }
      }

      button:hover {
        background-color: transparent;
        border-color: transparent;
        border-radius: 0;

        svg {
          fill: none;
          stroke: $red;

          path {
            fill: $red;
          }
        }
      }
    }
  }

}
