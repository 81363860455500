@use 'styles/abstracts' as *;

.model {
  padding: rem(40);
  @include breakpoint(medium) {
    padding: rem(40);
  }

  @include breakpoint(small-max) {
    padding: rem(20);
  }

  .msgDesc {
    margin-bottom: $element-v-spacing-lg;
    text-align: center;
    justify-content: center;

    span {
      b {
        font-weight: 700;
      }

    }
  }

  .descText {
    text-align: justify;
    justify-content: center;
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: rem(50);
    flex: 1;
    z-index: 9999999;

    a {
      margin-bottom: $element-v-spacing-lg;
      width: 100%;
    }

    @include breakpoint(medium) {
      a {
        width: auto;
      }

      *+* {
        margin-left: rem(12);
      }
    }

  }


}