/* --- Element Spacing --- */
$element-spacing-sm: 0.5rem;      // 8px
$element-spacing-md: 1rem;        // 16px
$element-spacing-lg: 1.375rem;    // 22px
$element-spacing-xl: 1.625rem;    // 26px
$element-spacing-xxl: 2rem;       // 32px
$element-spacing-xxxl: 3rem;      // 48px

/* --- Element Vertical Spacing --- */
$element-v-spacing-sm: 0.5rem;    // 8px
$element-v-spacing-md: 1rem;      // 16px
$element-v-spacing-lg: 1.5rem;    // 24px
$element-v-spacing-xl: 2rem;      // 32px

/* --- Component Spacing --- */
$component-spacing-zero: 0;       // 0
$component-spacing-sm: 1.25rem;   // 20px
$component-spacing-md: 2.5rem;    // 40px
$component-spacing-lg: 3.75rem;   // 60px
$component-spacing-xl: 5rem;      // 80px
$component-spacing-xxl: 6.25rem;  // 100px
$component-spacing-xxxl: 7.5rem;  // 120px