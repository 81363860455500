@use 'styles/abstracts' as *;

.large {
  $nav: &;
  position: relative;

  .container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: rem($content-width-max);
    position: relative;

    .wrapper {
      @include grid;
      @include grid--component-container;

      margin-inline: rem(16);
      max-width: rem($content-width-navigation);
      padding: rem(16) 0;
      position: relative;
      width: 100%;

      @include breakpoint(xlarge) {
        // Override default grid--component-container setting
        margin-inline: rem(16);
        max-width: rem($content-width-navigation);
      }

      @include breakpoint(navigation) {
        margin-inline: rem(0);
      }

      .bar {
        @include grid-column(1, span 12);

        align-items: center;
        background: $white;
        border-radius: rem(200);
        box-shadow: 0 rem(12) rem(24) rgb(9 7 37 / 7%);
        display: flex;
        height: rem(70);
        justify-content: space-between;
        min-width: rem(260);
        padding: 0 rem(16);
        position: relative;
        z-index: 20;

        > a {
          border-right: rem(0.5) solid $light-gray;
          margin-left: rem(16);
          z-index: 20;

          svg {
            margin-right: rem(24);
            max-height: rem(35);
          }

          @include breakpoint(navigation-logo) {
            svg {
              margin-right: 0;
            }
          }
        }

        ul {
          display: inline-flex;
          height: 100%;
          padding-top: rem(5);
          position: relative;
          right: rem(60);
          margin-left: rem(50);

          li {
            padding: 0 rem(16);
            a {
              align-items: center;
              border-bottom: rem(2) solid transparent;
              display: inline-flex;
              height: 100%;
              padding: 0;

              svg {
                max-width: rem(18);
                min-width: rem(18);
              }
            }

            a.isActive {
              border-bottom: rem(2) solid $red;

              span {
                color: $red;
              }

              svg {
                path {
                  stroke: $red;
                }
              }
            }

            a.highlight {
              border-bottom: rem(2) solid $black;

              span {
                font-family: 'HCo Gotham SSm', Helvetica, Arial, 'Lucida Grande', sans-serif;
                font-weight: 700;
                color: $black;
              }

              svg {
                path {
                  stroke: $black;
                }
              }
            }
          }
        }

        > button:last-child {
          height: rem(30);
          right: rem(32);
          position: absolute;
          width: rem(30);
          z-index: 20;

          svg {
            fill: none;
            margin-top: 0;
            max-width: rem(24);
            min-width: rem(24);
            stroke: $black;

            path {
              fill: $black;
            }
          }

          &:hover {
            svg {
              fill: none;
              stroke: $red;

              path {
                fill: $red;
              }
            }
          }
        }
      }

      .search-active {
        button:last-child:hover svg path {
          stroke: $red;
        }
      }
    }
  }

  .menu {
    bottom: 0;
    height: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: height 0.5s ease-in-out;
    width: 100%;
    z-index: 15;
  }

  .menu.isActive {
    height: 100vh;
  }

  .search {
    bottom: 0;
    height: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: height 0.5s ease-in-out;
    width: 100%;
    z-index: 15;
  }

  .search.isActive {
    height: 100vh;
  }

  .inner {
    display: flex;
    justify-content: center;
    margin: rem(80) auto;
    max-width: rem($content-width-max);
    padding: 0;
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);

    .blur {
      -webkit-backdrop-filter: blur(rem(15));
      backdrop-filter: blur(rem(15));
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: rem(25);
      bottom: 0;
      left: 50%;
      max-width: rem($content-width-navigation);
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: calc(100% - rem(32));
    }

    // Firefox specific workaround because backdrop-filter isn't supported
    @supports (-moz-appearance:none) {
      .blur {
        background-color: rgba(235, 235, 235, 0.99);
      }
    }

    .content {
      @include grid;
      @include grid--component-container;

      border-radius: rem(25);
      margin-inline: 0;
      max-width: rem($content-width-navigation);
      padding: rem(90) 0 0;
      position: relative;
      width: calc(100% - rem(32));

      @include breakpoint(xlarge) {
        // Override default grid--component-container setting
        max-width: rem($content-width-navigation);
      }

      @include breakpoint(navigation) {
        margin-inline: rem(0);
      }

    }
  }
}

/* TODO: For this to work it needs to be pulled up to the highest level Component & Header element */
.sticky {
  padding-top: rem(47);
  position: sticky;
  top: 0;
  z-index: 4;
}
