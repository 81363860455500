@use 'styles/abstracts' as *;

/*
 * Font Family, Text Size and Text Color are set within _typography.scss
 * ui.frontend/src/styles/base/_typography.scss
*/

.line-clamp-default {
  -webkit-box-orient: vertical;
  display: -webkit-inline-box;
  overflow: hidden;

  ul {
    list-style-type: disc;
    margin-top: -$element-v-spacing-lg;

    li {
      margin-left: $element-spacing-md;
    }

    br {
      display: none;
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: $element-spacing-md;
    }

    br {
      display: none;
    }
  }

  b {
    font-weight: 600
  }
  i {
    font-style: italic
  }
}

.line-clamp--1 {
  @extend .line-clamp-default;
  -webkit-line-clamp: 1;
}

.line-clamp--2 {
  @extend .line-clamp-default;
  -webkit-line-clamp: 2;
}

.line-clamp--3 {
  @extend .line-clamp-default;
  -webkit-line-clamp: 3;
}

.line-clamp--4 {
  @extend .line-clamp-default;
  -webkit-line-clamp: 4;
}

.line-clamp--5 {
  @extend .line-clamp-default;
  -webkit-line-clamp: 5;
}

.line-clamp--6 {
  @extend .line-clamp-default;
  -webkit-line-clamp: 6;
}

.line-clamp--7 {
  @extend .line-clamp-default;
  -webkit-line-clamp: 7;
}