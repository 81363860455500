@use 'styles/abstracts' as *;

.utility {
  $util: &;

  background-color: $midnight-blue;
  bottom: 0;
  left: 0;
  max-height: 0;
  right: 0;
  position: fixed;
  transition: max-height 0.5s ease-in-out;
  width: 100%;
  z-index: 16;

  .links {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(8);
    padding: 0 rem(13);
    row-gap: rem(0);

    svg + span {
      margin-left: 0;
    }

    li {
      padding: rem(6) rem(3) rem(5);

      a {
        text-transform: none;

        svg {
          height: rem(18);
          margin-left: 0;
          margin-right: 0;
          margin-top: rem(1);
        }
      }

      select {
        background-color: transparent;
        background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.85437L10 0.541986L0 0.541986L5 5.85437Z" fill="%23EBEBEB"/></svg>');
        background-position: 100% 50%;
        background-size: rem(10);
        border: 0;
        color: $white;
        margin: 0;
        padding: 0;
        font-size: rem(12);
        font-family: "HCo Gotham SSm"; // Used for testing in Storybook when body font isn't applied
      }

      // Fix for Safari in iOS < 14.5
      @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
          margin-bottom: rem(16);
        }
      }
    }

    li.country label,
    li.country select {
      max-width: rem(99);
      padding: rem(6) 0;
      width: rem(67);
    }

    li.shop label,
    li.shop select {
      max-width: rem(80);
      padding: rem(6) 0;
      width: rem(50);
    }
  } 
}

.utility.isActive {
  max-height: rem(100);
}